import { useDispatch } from "react-redux";
import { setShowDemoSignupModal } from "../slices/appStateSlice";
import { useAppSelector } from "./redux-hooks";
import React, { useState } from "react";

const useCheckDemoPath = () => {
  const dispatch = useDispatch();
  const { isDemoPath } = useAppSelector((state) => state.appState);

  const checkDemoPath = () => {
    if (isDemoPath) {
      dispatch(setShowDemoSignupModal(true));
    }
    return isDemoPath;
  };

  return checkDemoPath;
};

const useRootPath = () => {
  const [rootPath, setRootPath] = useState<string>("/home");
  const { isMobilePath } = useAppSelector((state) => state.appState);

  const getRootPath = () => {
    if (isMobilePath) {
      setRootPath("/notesforMobile");
    } else setRootPath("/home");
  };

  React.useEffect(() => {
    getRootPath();
  }, [isMobilePath]);

  return { rootPath, getRootPath };
};

export { useRootPath };

export default useCheckDemoPath;
