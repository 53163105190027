import {
  CloseOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Input,
  MenuProps,
  Typography,
  message,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { deleteClient, getAllClients } from "../../service/client.service";
import {
  setCreateClientState,
  setCustomiseSectionState,
  setLoadingNotesState,
  setNotesViewVisible,
  setSelectedClient,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import {
  resetClientForm,
  setClientForm,
  setClients,
  setIsEditing,
} from "../../slices/clientSlice";
import { resetNotesDetails, setNotesDetails } from "../../slices/notesSlice";
import type { TClientForm } from "../../types/index.type";
import { sortClientsByName } from "../../utils/datamanipulation.utils";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import EmptyStateClient from "../EmptyState/EmptyStateClient.component";
import styles from "./clientview.module.scss";
import { useRootPath } from "../../hooks/app.hook";

const ClientView = ({
  demoClients,
  addClientRef,
  clientViewRef,
}: {
  demoClients?: TClientForm[];
  addClientRef?: React.RefObject<HTMLDivElement>;
  clientViewRef?: React.RefObject<HTMLDivElement>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const componentName = "ClientView";

  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<TClientForm[]>(
    demoClients ? demoClients : []
  );
  const [debounceTimeout, setDebounceTimeout] =
    React.useState<NodeJS.Timeout>();

  const { rootPath } = useRootPath();

  const { selectedClient, isDemoPath, isMobilePath } = useAppSelector(
    (state) => state.appState
  );

  const { isRecording, isPaused } = useAppSelector((state) => state.recordings);

  const { clients } = useAppSelector((state) => state.client);

  const selectClient = (client: TClientForm | null, showLoader = true) => {
    if (
      selectedClient?.id &&
      client &&
      client.id &&
      selectedClient.id === client.id
    ) {
      return;
    }
    if (showLoader) {
      dispatch(setLoadingNotesState(true));
    }
    dispatch(setSelectedClient(client));
    dispatch(setNotesViewVisible(false));
    dispatch(setNotesDetails(null));
    dispatch(setCustomiseSectionState(false));
    dispatch(resetNotesDetails());
  };

  const handleClientSelectWithNavigate = (client: TClientForm) => {
    selectClient(client);
    navigate(`${rootPath}/clients/${client.uuid}`);
  };

  const clearSelection = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    navigate(rootPath);
    selectClient(null);
  };

  const showClientView = () => {
    dispatch(setCreateClientState(true));
    dispatch(setIsEditing(false));
    dispatch(resetClientForm());
    dispatch(setNotesViewVisible(false));
    dispatch(setNotesDetails(null));
    dispatch(setCustomiseSectionState(false));
    dispatch(resetNotesDetails());
  };

  // biome-ignore lint: no need to add selectedClient to the dependency array
  React.useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (searchTerm === "" && !demoClients) {
      setFilteredData(clients);
      return;
    }

    // Set a new timeout to debounce the filtering function
    const timeout = setTimeout(() => {
      const filtered = clients.filter(
        (item) =>
          `${item.name.toLowerCase()}`.includes(searchTerm.toLowerCase()) ||
          `${item.name.toLowerCase()}`.includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }, 300); // Adjust the delay (in milliseconds) as needed

    // Save the timeout id to be able to clear it later
    setDebounceTimeout(timeout);
  }, [clients, searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const fetchClients = () => {
    getAllClients()
      .then((response) => {
        const sortedClients = sortClientsByName(response.data);
        dispatch(setClients(sortedClients));
        setFilteredData(sortedClients);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    if (!demoClients) {
      fetchClients();
    }
  }, []);

  const handleMenu = (e: any) => {
    e.domEvent.stopPropagation();
    if (e.key === "1") {
      dispatch(setCreateClientState(true));
      if (selectedClient) dispatch(setClientForm(selectedClient));
      dispatch(setIsEditing(true));
    }
    if (e.key === "2") {
      if (isDemoPath) {
        dispatch(setShowDemoSignupModal(true));
        return;
      }
      Swal.fire({
        title: "Would you like to delete this client?",
        text: "The client's notes will still be available unless you delete them.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed && selectedClient && selectedClient.id) {
          deleteClient(selectedClient.id)
            .then(() => {
              navigate(rootPath);
              fetchClients();
              dispatch(setSelectedClient(null));
              message.success("Client deleted successfully");
            })
            .catch((error) => {
              console.error(error);
              message.error("Failed to delete client. Try Again later");
            });
        }
      });
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Edit Client</span>,
    },
    {
      key: "2",
      label: <span>Delete Client</span>,
    },
  ];

  return (
    <Flex
      vertical
      gap={10}
      className={
        !isRecording && !isPaused
          ? styles[`${componentName}__container`]
          : isRecording
          ? styles[`${componentName}__container-active-recording`]
          : isPaused
          ? styles[`${componentName}__container-paused-recording`]
          : ""
      }
      ref={clientViewRef}
    >
      <Flex justify="space-between" align="center">
        <Typography className={styles[`${componentName}__container-title`]}>
          Clients
        </Typography>
        <Button
          className="secondary-button"
          type="primary"
          size="middle"
          ref={addClientRef}
          icon={<PlusOutlined />}
          onClick={showClientView}
          style={{ padding: "0 10px" }}
        >
          Add Client
        </Button>
      </Flex>
      <Flex>
        <Input
          value={searchTerm}
          disabled={clients.length === 0}
          prefix={<SearchOutlined />}
          placeholder="Search Clients"
          onChange={handleInputChange}
          allowClear
        />
      </Flex>
      {
        <Flex
          vertical
          className={
            styles[
              `${componentName}__container-scroll${
                filteredData && filteredData.length === 0 ? "-empty" : ""
              }`
            ]
          }
        >
          {filteredData && filteredData.length ? (
            filteredData.map((data, index) => (
              <Flex
                key={index}
                gap={10}
                align="center"
                justify="space-between"
                className={`${styles[`${componentName}__container-client`]} ${
                  selectedClient && selectedClient.id === data.id
                    ? styles[`${componentName}__container-client-selected`]
                    : ""
                }`}
                onClick={() => handleClientSelectWithNavigate(data)}
              >
                <Flex align="center" gap={10}>
                  <Avatar
                    size={"small"}
                    className="special-avatar"
                    style={{
                      backgroundColor: getRandomAvatarColor(data.name),
                    }}
                  >
                    {data.name.charAt(0)}
                  </Avatar>
                  <Typography
                    className={`${
                      styles[`${componentName}__container-client-name`]
                    }`}
                  >{`${data.name}`}</Typography>
                </Flex>
                {selectedClient && selectedClient.id === data.id && (
                  <Flex gap={10}>
                    <CloseOutlined
                      style={{ color: "var(--indigo-700)" }}
                      onClick={clearSelection}
                    />
                    <Dropdown
                      menu={{ items, onClick: handleMenu }}
                      placement="bottomLeft"
                      arrow
                      trigger={["click"]}
                    >
                      <MoreOutlined style={{ color: "var(--indigo-700)" }} />
                    </Dropdown>
                  </Flex>
                )}
              </Flex>
            ))
          ) : (
            <EmptyStateClient />
          )}
        </Flex>
      }
    </Flex>
  );
};

export default ClientView;
