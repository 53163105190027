import styles from "./emptystate.module.scss";

const EmptyStateNote = () => {
  const component = "emptystate";

  return (
    <div className={styles[`${component}__container`]}>
      <span
        className={styles[`${component}__container-text`]}
        style={{
          fontSize: "1em",
          lineHeight: "1.5em",
        }}
      >
        No notes created yet.
      </span>
    </div>
  );
};

export default EmptyStateNote;
