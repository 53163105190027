import type { UserInfoProps } from "../slices/authSlice";
import { GWUL_GROUP_NAME, LLT_GROUP_NAME, SOL_GROP_NAME } from "../utils/constants";

export function isFromGWUL(userInfo: UserInfoProps) {
  return userInfo.group_name === GWUL_GROUP_NAME;
}

export function isFromSOL(userInfo: UserInfoProps) {
  return userInfo.group_name === SOL_GROP_NAME;
}

export function isFromLLT(userInfo: UserInfoProps) {
  return userInfo.group_name === LLT_GROUP_NAME;
}
