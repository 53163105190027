import React from "react";

const BulletPoint: React.FC<{ backgroundColor: string; size: number }> = ({
  backgroundColor,
  size,
}) => {
  return (
    <div
      style={{
        backgroundColor,
        width: size,
        height: size,
        borderRadius: "50%",
      }}
    />
  );
};

const CustomLabel: React.FC<{ viewBox?: any; value: number }> = ({
  viewBox,
  value,
}) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#111827"
        fontSize={18}
        fontWeight="500"
      >
        {value}
      </text>
    </g>
  );
};

const CustomizedLegend: React.FC<{ payload?: any }> = ({ payload }) => {
  return (
    <ul
      style={{
        height: 250,
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        listStyle: "none",
        width: "max-content",
      }}
    >
      {payload.map((entry: any, index: any) => (
        <li
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 40,
          }}
          key={`item-${index}`}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#6b7280",
              width: 350,
            }}
          >
            <BulletPoint backgroundColor={entry.payload.fill} size={10} />
            <div
              style={{
                width: "75%",
                marginLeft: 8,
                lineHeight: "1.25em",
                textAlign: "left",
              }}
            >
              {entry.value}
            </div>
            <div style={{ color: "black" }}>
              {entry.payload.value} (
              {Math.floor((entry.payload.value * 100) / 153)}%)
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export { BulletPoint, CustomLabel, CustomizedLegend };
