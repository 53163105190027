const PreWrapText = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-wrap",
      }}
    >
      {text}
    </div>
  );
};

export default PreWrapText;
