import { CATEGORY } from "../../domain/notes.domain";

type Props = {
  category_type?: number;
};

export default function CategoryType({ category_type }: Props) {
  const style = { color: "#1E3A8A", fontSize: "14px", fontWeight: "500" };
  if (category_type === CATEGORY.INDIVIDUAL) return <span style={style}>Individual</span>;
  else if (category_type === CATEGORY.RELATIONSHIP) return <span style={style}>Relationship</span>;
  else if (category_type === CATEGORY.FAMILY) return <span style={style}>Family</span>;
  else if (category_type === CATEGORY.GROUP) return <span style={style}>Group</span>;
  else return <></>;
}
