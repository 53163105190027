import { Button, Card, Flex } from "antd";
import styles from "./notes.module.scss";
import { CopyOutlined } from "@ant-design/icons";
import {
  copySessionDetails,
  durationTextFromType,
} from "../../utils/datamanipulation.utils";
import { useAppSelector } from "../../hooks/redux-hooks";
import LocationType from "../../components/Location/LocationType.component";

const SessionDetails = () => {
  const componentName = "notes";

  const { notesDetails } = useAppSelector((state) => state.notes);

  if (!notesDetails) {
    return null;
  }

  return (
    <Flex
      gap={10}
      vertical
      style={{
        marginBottom: "20px",
      }}
    >
      <Flex vertical gap={20}>
        <Flex>
          <Card
            className={`${styles[`${componentName}__main-section`]}`}
            hoverable
          >
            <Flex justify="space-between" align="center">
              <span
                className={`${styles[`${componentName}__main-section-title`]}`}
              >
                Session Details:
              </span>{" "}
              <Button
                type="default"
                className={`${
                  styles[`${componentName}__main-section-copy-button`]
                }`}
                onClick={() => copySessionDetails(notesDetails)}
                icon={<CopyOutlined />}
              >
                Copy
              </Button>
            </Flex>
            <div
              className={`${
                styles[`${componentName}__main-section-description`]
              }`}
            >
              {notesDetails.location_type !== undefined &&
                notesDetails.location_type !== null && (
                  <Flex
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span>
                      <strong>Location: </strong>
                    </span>
                    &nbsp;
                    <LocationType
                      isStyled={false}
                      location_type={notesDetails.location_type}
                    />
                  </Flex>
                )}
              {notesDetails.duration_type !== undefined &&
              notesDetails.duration_type !== null ? (
                <Flex
                  align="flex-start"
                  className={`${
                    styles[`${componentName}__main-section-description-col`]
                  }`}
                >
                  <span
                    className={`${
                      styles[
                        `${componentName}__main-section-description-col-label`
                      ]
                    }`}
                  >
                    <strong>CPT Code: </strong>
                  </span>{" "}
                  <span>
                    {durationTextFromType(notesDetails.duration_type as number)}
                  </span>
                </Flex>
              ) : null}
            </div>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SessionDetails;
