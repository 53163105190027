import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, Flex, Select, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setAddClientView } from "../../slices/appStateSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./createnotemodal.module.scss";
import { useIsMobileView } from "../../hooks/ui-hook";

const ClientSelector = () => {
  const componentName = "createnotemodal";
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileView();
  const { isEditNote } = useAppSelector((state) => state.appState);
  const { clients } = useAppSelector((state) => state.client);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  return (
    <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
      <Typography.Text>Client:</Typography.Text>
      <Select
        value={recordingDetail?.client_id}
        listHeight={isMobile ? 66 : 256}
        disabled={isEditNote}
        onChange={(value) => {
          dispatch(
            setRecordingDetail({
              ...recordingDetail,
              client_id: value,
            })
          );
        }}
        showSearch
        placeholder="Select a client"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input?.toLowerCase())
        }
        optionRender={(client) => {
          return (
            <Flex gap={10}>
              <Avatar
                style={{
                  backgroundColor: getRandomAvatarColor(client.label as string),
                }}
                size="small"
              >
                {`${client?.label || ""}`.charAt(0).toUpperCase()}
              </Avatar>
              <Typography.Text>{client.label}</Typography.Text>
            </Flex>
          );
        }}
        dropdownRender={(menu) => (
          <div className="client">
            {menu}
            {!isEditNote ? (
              <>
                <Divider style={{ margin: "4px 0" }} />
                <Flex gap={10}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      dispatch(setAddClientView(true));
                    }}
                  >
                    Add Client
                  </Button>
                </Flex>
              </>
            ) : null}
          </div>
        )}
        className={`${styles[`${componentName}__modal-row-field`]}`}
        options={clients.map((client) => ({
          label: client.name,
          value: client.id,
        }))}
      />
    </Col>
  );
};

export default ClientSelector;
