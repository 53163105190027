import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useAudioStatusCheck } from "../../hooks/useAudioStatusCheck";
import { getNotesListByClientUUID } from "../../service/notes.service";
import {
  setLoadingNotesState,
  setSelectedClient,
} from "../../slices/appStateSlice";
import NotesSection from "./NotesSection.component";
import { eventEmitter } from "../../utils/eventEmitter";

const ClientNotes = () => {
  const dispatch = useAppDispatch();
  const { clientUuid } = useParams<{ clientUuid: string }>();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { clients } = useAppSelector((state) => state.client);
  const { isNotesViewVisible } = useAppSelector((state) => state.appState);
  const { notes, updateNotes: setNotes } = useAudioStatusCheck([]);
  const [totalNotes, setTotalNotes] = useState(0);

  const fetchNotes = useCallback(
    async (pageNumber = 1) => {
      if (!clientUuid) return;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      try {
        dispatch(setLoadingNotesState(true));
        const response = await getNotesListByClientUUID(
          clientUuid,
          pageNumber,
          abortControllerRef.current.signal
        );
        const data = await response.data;
        setNotes(data?.results);
        setTotalNotes(data?.count);
      } catch (error) {
        console.error("Error fetching client notes:", error);
      } finally {
        dispatch(setLoadingNotesState(false));
      }
    },
    [clientUuid, dispatch, setNotes]
  );

  useEffect(() => {
    if (clientUuid) {
      const client = clients.find((c) => c.uuid === clientUuid);
      if (client) {
        dispatch(setSelectedClient(client));
      }
    }
    if (!isNotesViewVisible) {
      fetchNotes();
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [clientUuid, fetchNotes, dispatch, clients, isNotesViewVisible]);


  const refreshHandler = useCallback(() => {
    fetchNotes();
  }, [fetchNotes]);

  useEffect(() => {
    eventEmitter.on('refreshNotes', refreshHandler);

    return () => {
      eventEmitter.off('refreshNotes', refreshHandler);
    };
  }, [refreshHandler]);

  return (
    <NotesSection
      notes={notes}
      setNotes={setNotes}
      totalNotes={totalNotes}
      fetchNotes={fetchNotes}
      resetRecordingState={() => {}}
    />
  );
};

export default ClientNotes;