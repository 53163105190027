import { useState, useCallback, useEffect, useRef } from "react";
import { getNotesDetails, setTimeOutReached } from "../service/notes.service";
import { isMaxTimedOut } from "../utils/recording.utils";
import type { TNotes } from "../types/index.type";
import { useAppDispatch } from "./redux-hooks";
import { updateUserInfo } from "../slices/authSlice";

export const useAudioStatusCheck = (initialNotes: TNotes[]) => {
  const [notes, setNotes] = useState<TNotes[]>(initialNotes);
  const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({});
  const dispatch = useAppDispatch();

  const setMaxTimeOutReached = useCallback(async (recording: TNotes) => {
    const updateNoteData = {
      audio_id: recording.id,
      error_status: "err_timed_out",
    };
    await setTimeOutReached(updateNoteData);
  }, []);

  const isCheckingAudioStatus = useCallback((note: TNotes) => {
    return note.status !== 1 && note.status !== 2 && !isMaxTimedOut(note) && note.error_status === 0;
  }, []);

  const checkAudioStatus = useCallback(
    async (note: TNotes) => {
      try {
        const response = await getNotesDetails(note.id);
        const data = await response.data;
        setNotes((prev) =>
          prev.map((prevNote) => {
            if (prevNote.id === data.id) {
              if (prevNote.status !== 1 && data.status === 1) {
                dispatch(updateUserInfo());
              }
              return { ...prevNote, ...data };
            }
            return prevNote;
          })
        );
        if (isCheckingAudioStatus(data)) {
          // Set a new timeout for this note
          timeoutsRef.current[note.id] = setTimeout(
            () => checkAudioStatus(data),
            3000
          );
        } else if (note.status !== 1 && isMaxTimedOut(data)) {
          setMaxTimeOutReached(data);
        }
      } catch (error) {
        console.error("Error checking audio status:", error);
      }
    },
    [isCheckingAudioStatus, setMaxTimeOutReached, dispatch]
  );

  useEffect(() => {
    for (const note of notes) {
      if (isCheckingAudioStatus(note) && !timeoutsRef.current[note.id]) {
        timeoutsRef.current[note.id] = setTimeout(
          () => checkAudioStatus(note),
          3000
        );
      }
    }

    return () => {
      Object.values(timeoutsRef.current).forEach(clearTimeout);
      timeoutsRef.current = {};
    };
  }, [notes, checkAudioStatus, isCheckingAudioStatus]);

  const updateNotes = useCallback((newNotes: TNotes[]) => {
    setNotes(newNotes);
    // Clear existing timeouts when notes are updated
    Object.values(timeoutsRef.current).forEach(clearTimeout);
    timeoutsRef.current = {};
  }, []);

  return { notes, updateNotes };
};
