import styles from "./docs.module.scss";

export default function Privacy() {
  const componentName = "docs";
  return (
    <>
      <div className={`${styles[`${componentName}__container`]}`}>
        <div className={`${styles[`${componentName}__container-header`]}`}>
          Privacy Policy
        </div>
        <div className={`${styles[`${componentName}__container-desc`]}`}>
          <span>
            <b>Privacy Policy for Nudge</b>
          </span>
          <br />
          <br />
          At Nudge, we are committed to protecting the privacy and personal
          information of our users. This Privacy Policy outlines how we collect,
          use, store, and share the data provided by therapists and mental
          health professionals who use our platform. By using our services, you
          agree to the practices described in this policy.
          <br />
          <br />
          <ol>
            <li>
              <b>Information We Collect:</b>
              <ul>
                <li>
                  <b>Contact Information:</b> Name, email address, phone number,
                  and address.
                </li>
                <li>
                  <b>Account Information:</b> Username and registration details.
                </li>
                <li>
                  <b>Profile Information:</b> Profile picture, gender, links to
                  promotional content, user-entered content regarding mental
                  well-being challenges, and personal description.
                </li>
                <li>
                  <b>Communication Information:</b> Messages and customer
                  support requests sent through our platform.
                </li>
                <li>
                  <b>Usage Data:</b> IP address, browser type, device
                  information, and interactions with our platform.
                </li>
              </ul>
            </li>
            <li>
              <b>How We Use Your Information</b>
              <ul>
                <li>
                  To provide and improve our services, including transcription
                  and note-generation for therapy sessions.
                </li>
                <li>
                  To communicate with you about your account, product updates,
                  and customer support.
                </li>
                <li>
                  To analyze user behavior and interactions with our platform to
                  enhance user experience.
                </li>
                <li>
                  To comply with legal obligations and protect our rights and
                  the rights of our users.
                </li>
              </ul>
            </li>
            <li>
              <b>Data Sharing and Disclosure</b>
              <ul>
                <li>
                  We may share your information with trusted third-party service
                  providers who assist us in delivering our services, such as
                  hosting providers or analytics platforms.
                </li>
                <li>
                  We will not sell or rent your personal information to third
                  parties for marketing purposes.
                </li>
                <li>
                  We may disclose your information if required by law or to
                  protect our rights, property, or safety, or that of our users.
                </li>
              </ul>
            </li>
            <li>
              <b>Data Security</b>
              <ul>
                <li>
                  We implement industry-standard security measures to protect
                  your personal information from unauthorized access,
                  alteration, or disclosure.
                </li>
                <li>
                  All data is encrypted in transit and at rest, and access is
                  limited to authorized personnel only.
                </li>
                <li>
                  However, no method of transmission over the internet or
                  electronic storage is 100% secure, and we cannot guarantee
                  absolute security.
                </li>
              </ul>
            </li>
            <li>
              <b>Your Rights and Choices</b>
              <ul>
                <li>
                  You have the right to access, update, and correct your
                  personal information.
                </li>
                <li>
                  You may also request the deletion of your personal data,
                  subject to legal requirements and our legitimate business
                  interests.
                </li>
                <li>
                  You can opt out of receiving promotional communications from
                  us by following the unsubscribe instructions in those
                  messages.
                </li>
              </ul>
            </li>
            <li>
              <b>Compliance with Privacy Laws</b>
              <ul>
                <li>
                  We adhere to the privacy laws and regulations applicable in
                  the jurisdictions where we operate, including HIPAA and PHIA.{" "}
                </li>
                <li>
                  We implement appropriate safeguards for cross-border data
                  transfers to ensure the protection of personal information.
                </li>
              </ul>
            </li>
            <li>
              <b>Changes to This Privacy Policy</b>
              <ul>
                <li>
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or legal requirements.
                </li>
                <li>
                  We will notify you of any significant changes and obtain your
                  consent when necessary.
                </li>
              </ul>
            </li>
            <li>
              <b>Contact Us: </b>
              <ul>
                <li>
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or our data practices, please contact us
                  at contact@getnudgeai.com
                </li>
              </ul>
            </li>
          </ol>
          <div>
            By using our services, you acknowledge that you have read and
            understood this Privacy Policy and agree to the collection, use, and
            sharing of your information as described herein.
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <b>Effective Date:</b> 12th April, 2024.
          </div>
        </div>
      </div>
    </>
  );
}
