import styles from "./docs.module.scss";

export default function Terms() {
  const componentName = "docs";
  const underline = { textDecoration: "underline" };
  return (
    <>
      <div className={`${styles[`${componentName}__container`]} tos`}>
        <div className={`${styles[`${componentName}__container-header`]}`}>
          TERMS AND CONDITIONS
        </div>
        <div className={`${styles[`${componentName}__container-desc`]}`}>
          Welcome to Musicbox Inc., dba Nudge AI’s (hereafter “Nudge”, "we",
          "our," or "us"), online website and all content and functionality
          available through the https://getnudgeai.com/ domain name (the
          "Site"). Our Site and our product and features (including existing and
          future products and features), related data, proprietary software, and
          content and related documentation and information and any such
          services provided by us, including access to our Platform (as defined
          herein) (collectively the “Services”), allows you, our users (the
          terms “you”, “your”, and “yours” shall refer to any and all users of
          the Site (the “Users”), including an Entity (as defined herein)), to
          access our generative AI note and documentation services focused on
          mental health. <br />
          <br />
          Before using the Site, accessing the Platform, and/or the Services, we
          encourage you to carefully read these Terms and Conditions (the
          “Agreement”). We may modify this Agreement at any time, and such
          modification will be effective immediately upon either posting of the
          modified Agreement or notifying you. You agree to review this
          Agreement periodically to ensure that you are aware of any
          modifications, and you agree that your continued access or use of the
          Site, Platform and/or Services shall be deemed your conclusive
          acceptance of the modified Agreement.
          <br />
          <br />
          BY VISITING AND PROVIDING INFORMATION TO US THROUGH THE SITE,
          REGISTERING FOR AN ACCOUNT (AN “ACCOUNT”), ACCESSING OR USING ANY PART
          OF THE SITE AND/OR PLATFORM, YOU EXPRESSLY AGREE TO AND CONSENT TO BE
          BOUND BY (A) THE TERMS AND CONDITIONS THIS AGREEMENT, AND (B) OUR
          PRIVACY POLICY, WHICH CAN BE FOUND AT
          HTTPS://GETNUDGEAI.COM/PRIVACY-POLICY (THE “PRIVACY POLICY”).{" "}
          <strong>
            IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT AND/OR THE
            PRIVACY POLICY, YOU DO NOT HAVE OUR AUTHORIZATION TO USE ANY OF THE
            SERVICES AND YOU MAY NOT ACCESS OR USE ANY PORTION OF THE SITE OR
            PLATFORM.{" "}
          </strong>
          <br />
          <br />
          <ol>
            <li>
              <b>SERVICE TERMS AND LIMITATIONS.:</b><br /><br />
              <ol>
                <li>
                  <b style={underline}>Proprietary Rights.</b>The entire contents displayed on the Site and the Platform
                  (the “Content”) have copyright protection as a collective work
                  under the laws of the United States and other copyright laws.
                  We are the sole exclusive owner of the Content. There may be
                  collective work that is the property of other third parties
                  and such collective work is also protected by copyright and
                  other intellectual property laws. You are allowed to display
                  and, subject to any expressly stated restrictions or
                  limitations relating to specific material, download portions
                  of the Content from the different areas of the Site or
                  Platform only for non-commercial use, unless otherwise
                  permitted. Any redistribution, retransmission or publication
                  of any copyrighted material is strictly prohibited without the
                  express written permission of the copyright owner. You may not
                  change or delete any proprietary notices from materials
                  downloaded from the Site or the Platform. You agree not to use
                  any our logo or any other proprietary graphic or trademark
                  without our express written consent. As between the parties,
                  title, ownership rights, and intellectual property rights in
                  the Content, and any copies or portions thereof, shall remain
                  in the Company and/or its content providers. Third-party
                  trademarks, service marks, and logos contained in the Site and
                  Platform are owned and licensed by their respective owners.
                  Any and all rights not expressly granted herein are reserved.
                  The Services and their original content (excluding Content
                  provided by users), features, and functionality are and will
                  remain the exclusive property of Nudge AI and its licensors.
                  The Services are protected by copyright, trademark, and other
                  laws of the United States and foreign countries. Our
                  trademarks and trade dress may not be used in connection with
                  any product or service without the prior written consent of
                  Nudge AI.
                </li><br />
                <li>
                  <b style={underline}>Grant of Limited License.</b> Your access to the Service is
                  licensed and not sold. Subject to the terms of this Agreement,
                  and upon registration of your Account (as applicable), we
                  hereby grant you a revocable, non-exclusive, non-transferable
                  account enabling you to access and use the Services, the Site
                  and the Platform, as applicable. All the Content that is made
                  available to view and/or download in connection with the Site
                  and Platform is owned by us and is our copyrighted work and/or
                  our suppliers and is licensed, not sold. You do not have the
                  right to lend, lease, rent or sublicense the Site, the
                  Platform and/or the Content. Your use of the Services
                  (including any Documentation (as defined herein), text,
                  software, photos, video, graphics, and music, sound or other
                  multimedia files that might accompany it (collectively,
                  “Material”)) is governed by the terms of this Agreement. We
                  reserve the right, without notice and in our sole discretion,
                  to terminate your license to use the Site, the Platform and
                  Services, and to block or prevent future access to and use of
                  the Site, the Platform and Services. You are not permitted,
                  directly or indirectly, and the foregoing license grant does
                  NOT include the right for you to (a) publish, publicly perform
                  or display, or distribute to any third party any Materials,
                  including reproduction on any computer network or broadcast or
                  publications media; (b) market, sell or make commercial use of
                  the Site, the Platform or any Material; (c) systematically
                  collect and use of any data or content including the use of
                  any data spiders, robots, or similar data gathering, mining or
                  extraction methods; (d) make derivative uses of the Site, the
                  Platform or the Material; or (e) use, frame or utilize framing
                  techniques to enclose any portion of the Site or Platform
                  (including the images found at this Site or Platform or any
                  text or the layout/design of any page or form contained on a
                  page). As used herein “Documentation” means any and all
                  documents available by Nudge through the Site, including, but
                  not limited to Nudge Session Insights® and Nudge After-Visit
                  Summaries®.
                </li><br />
                <li>
                  <b style={underline}>User’s Restrictions. </b> You are not permitted, directly
                  or indirectly, to (a) modify, translate, reverse engineer,
                  decompile, disassemble (except to the extent applicable laws
                  specifically prohibit such restriction), create derivative
                  works based on the Material; (b) copy the Material or the
                  Content or engage in any acts inconsistent with the principles
                  of copyright protection and fair use, as codified in 17 U.S.C.
                  Sections 106-110, without obtaining the express written
                  consent of the Company and/or the copyright owner; (c)
                  distribute, display (except for the purposes set forth in
                  Section 2), rent, lease, transfer or otherwise transfer rights
                  to, or in any way exploit, the Content or the Material, in
                  whole or in part; or (d) remove any proprietary notices or
                  labels on the Material or the Content.
                </li><br />
                <li>
                  <b style={underline}>User Agreement.</b><br /><br />
                  <ol>
                    <li>
                      <b>Platform Use; Account; Use of Services.</b><br /><br />
                    </li>
                    <ol>
                      <li>
                        As used herein “Platform” means our proprietary platform
                        which can be accessed via the Site where Users can,
                        among other things, use our Services to record, dictate,
                        write or upload your sessions, transcribe current and
                        past sessions, receive Nudge Session Insights®, receive
                        Nudge After-Visit Summaries® which can be emailed to
                        your patients following your session, and send and
                        receive SMS Messages (as defined herein). Further
                        information on our Services can be found on our Site.
                      </li><br />
                      <li>
                        In order to use the Services, whether under a free trial
                        or a paid Subscription (as defined herein), you will
                        need to register for an Account. In registering for and
                        maintaining an Account, you agree to: (A) provide true,
                        accurate, current and complete information, including
                        usernames and login information; (B) provide true,
                        accurate, current and complete information regarding
                        your credit card information (your “Credit Card”) as
                        applicable; (C) maintain and promptly update your
                        Account to keep it true, accurate, current and complete;
                        and (D) authorize us and our Affiliates (as defined
                        herein) to charge your Credit Card for any and all fees
                        (the “Fees”) incurred by you for your use of the
                        Services. If you provide any information that is untrue,
                        inaccurate, not current or incomplete, or we have
                        reasonable grounds to suspect that such information is
                        untrue, inaccurate, not current or incomplete, we have
                        the right to suspend or terminate your Account and
                        refuse any and all current or future use of the Services
                        (or any portion thereof). <br />
                      </li><br />
                    </ol>
                    <li>
                      You agree to notify us of any known or suspected
                      unauthorized user(s) of your Account, or any known or
                      suspected breach of security, including loss, theft, or
                      unauthorized disclosure of your password. You will never
                      be required to reveal your password to us or any of our
                      representatives or agents.
                    </li><br />
                    <li>
                      <b>[SMS Messages.</b><br /><br />
                      <ol>
                        <li>
                          As part of the Services and/or in response to any of
                          our advertisements (an “Ad”), you must opt-in by
                          providing us with your mobile phone number (“Opt-in”)
                          in order to receive or send SMS Messages and/or
                          receive mobile notifications from us in connection
                          with the Services. By providing your mobile phone
                          number to us, either directly through the Services or
                          in connection with an Ad, you are voluntarily and
                          consenting to Opt-in and you agree to receive
                          recurring SMS messages from us at the mobile phone
                          number associated with your Opt-in, even if such
                          number is registered on any state or federal “Do Not
                          Call” list. You agree that any mobile phone number you
                          provide to us is a valid mobile phone number of which
                          you are the owner or authorized user. If you change
                          your mobile phone number or are no longer the owner or
                          authorized user of the mobile phone number, you agree
                          to promptly notify us at contact@getnudgeai.com. You
                          can cancel notifications and other communications via
                          SMS Message in connection with our Services at any
                          time by texting “STOP” to (669) 285 - 9142 and we will
                          send you a reply confirming your request to
                          unsubscribe. After unsubscribing, you will no longer
                          receive messages from us. In the event you unsubscribe
                          and then choose to use the Services, you will need to
                          Opt-in again. If at any time you forget what keywords
                          are supported, you can text "HELP" to (669) 285 - 9142
                          and we will respond with instructions on how to use
                          the Service as well as how to unsubscribe. “SMS
                          Messages” means short message service messages, also
                          commonly known as text messages.
                        </li><br />
                        <li>
                          In order to use the Services, your Wireless Provider
                          (as defined herein) must be able to provide you with
                          services necessary to send and receive text messages.
                          You understand, acknowledge and agree that (A) text
                          message and data rates may apply for any SMS Messages
                          sent to you from us or from you to us; (B) you are
                          solely responsible for any fees charged by your
                          Wireless Provider in connection with SMS Messages that
                          you receive or send in connection with the Opt-in
                          and/or the Services; and (C) you will contact your
                          Wireless Provider with any questions regarding your
                          text and/or data plan. “Wireless Providers” include
                          AT&T and Verizon Wireless.
                        </li><br />
                        <li>
                          You understand and agree that neither Nudge nor your
                          Wireless Providers is liable for lost or delayed
                          messages. Nudge reserves the right at any time and
                          from time to time to modify, suspend, limit the use
                          of, discontinue or permanently cancel all or any
                          portion of the SMS Message services.]
                        </li><br />
                      </ol>
                    </li>
                    <li>
                      <b>Use of the Platform.</b><br /><br />
                      <ol>
                        <li>
                          In addition to the terms set forth in this Agreement,
                          your use of the Platform is subject to the following
                          additional terms (the “Platform Terms”): You
                          understand and agree that (A) your use of the Platform
                          is conditioned upon your acceptance of these Platform
                          Terms, (B) the Platform contains copyrighted material,
                          trade secrets, and other proprietary materials of
                          Nudge and its licensors, and (C) in order to protect
                          those proprietary materials, except as expressly
                          permitted by applicable law, neither you nor a third
                          party acting on your behalf will: (1) decompile,
                          disassemble or reverse engineer the Platform; (2)
                          modify or create derivative works of the Platform; (3)
                          use the Platform in any manner to provide service
                          bureau, commercial time-sharing or other computer
                          services to third parties; (4) transmit the Platform
                          or provide its functionality, in whole or in part,
                          over the Internet or other network (except as
                          expressly permitted above); (5) sell, distribute,
                          rent, lease, sublicense or otherwise transfer the
                          Platform to a third party; or (6) use components of
                          the Platform to run applications not running on the
                          Platform. 
                        </li><br />
                        <li>
                          YOU HEREBY AGREE THAT YOU WILL: (A) only use the
                          Platform to access and/or use the Services in
                          compliance with the terms of this Agreement; (B) not
                          use any software or services in conjunction with the
                          Services or authorized third-party software which
                          modifies or reroutes, or attempts to modify or
                          reroute, the Services; (C) not authorize any third
                          party to access and/or use the Services on your behalf
                          using any automated process such as a BOT, a spider or
                          periodic caching of information stored by the Services
                          on your behalf without a separate written agreement
                          with Nudge; (D) not use any software or hardware that
                          reduces the number of Users directly accessing or
                          using the Service (sometimes called 'multiplexing' or
                          'pooling' software or hardware); (E) not lend, lease,
                          rent or sublicense the Platform; (F) permit Nudge to
                          send and deliver updates to you as part of your use of
                          the Platform; and (G) allow the Platform to
                          automatically download and install updates from time
                          to time from Nudge which are designed to improve,
                          enhance and further develop the Platform and may take
                          the form of bug fixes, enhanced functions, new
                          software modules and completely new versions.
                        </li><br />
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b style={underline}>User Representations</b><br /><br />
                  <ol>
                    <li>
                      Any use of the Services is void where prohibited. By
                      accessing and using the Site and/or Platform, you
                      represent and warrant that (i) you are 18 years of age or
                      older and have the power, authority or consent to enter
                      into and perform your obligations under the Agreement;
                      (ii) all information provided by you to us, including
                      Credit Card information, is truthful, accurate and
                      complete; (iii) you are authorized, or have the permission
                      of the authorized signatory of the Credit Card provided to
                      us, to pay any Fees incurred from use of the Services;
                      (iii) if you register for an Account on behalf of an
                      Entity, you are authorized, or have the permission from
                      such Entity, to create such Account; and (v) you shall
                      comply with all terms and conditions of this Agreement. As
                      used herein, “Entity” means a business, organization,
                      company or group.
                    </li><br />
                    <li>
                      You represent and warrant that you will (i) maintain the
                      security of your user identification, password and other
                      confidential information relating to your Account; (ii)
                      maintain the security, confidentiality and integrity of
                      all messages and the content that you receive, transmit
                      through or store on the Service; (iii) maintain all
                      charges resulting from the use of your Account, including
                      but not limited to, unauthorized use of your Account prior
                      to you notifying us in writing of such use and taking
                      steps to prevent its further occurrence by changing your
                      password; (iv) comply with the terms set forth herein; and
                      (v) comply with all applicable U.S. and international
                      laws, statutes, ordinances, rules, regulations, contracts
                      and applicable licenses, including, but not limited to,
                      the Healthcare Insurance Portability and Accountability
                      Act (“HIPAA”), in connection with your use of the
                      Services, the Platform or Site.{" "}
                    </li><br />
                    <li>
                      You further warrant, represent and agree that you will not
                      use the Site, Platform or Services, including any Content
                      and Materials, in a manner that:<br /><br />
                      <ol>
                        <li>
                          infringes the intellectual property rights or
                          proprietary rights, or rights of publicity or privacy,
                          of any third party;
                        </li><br />
                        <li>
                          violates any law, statute, ordinance or regulation,
                          including, but not limited to HIPAA;
                        </li><br />
                        <li>
                          you should know is harmful, threatening, abusive,
                          harassing, tortious, defamatory, vulgar, obscene,
                          libelous, fraudulent, deceptive, or misleading or
                          otherwise objectionable;
                        </li><br />
                        <li>
                          adversely affects or reflects negatively on our
                          goodwill, name or reputation or causes duress,
                          distress or discomfort to us or anyone else, or
                          discourages any person, firm or enterprise from using
                          all or any portion, feature, or function of the Site
                          or Platform, or from advertising, liking or becoming a
                          supplier to use in connection with the Site or
                          Platform;
                        </li><br />
                        <li>
                          send or result in the transmission or junk e-mail,
                          chain letters, duplicative or unsolicited messages, or
                          so-called “spamming”;
                        </li><br />
                        <li>
                          transmit, distribute or upload programs or material
                          that contain malicious code, such as viruses,
                          timebombs, cancelbots, worms, trojan horse, spyware,
                          or other potentially harmful programs or other
                          material or information;
                        </li><br />
                        <li>falsely report to our employees or agents;</li><br />
                        <li>
                          circumvent, disable or otherwise interfere with
                          security-related features of the Site or Platform or
                          its features that prevent or restrict use or copying
                          of any content;
                        </li><br />
                        <li>
                          intercept or attempt to intercept email or other
                          private communications not intended for you;
                        </li><br />
                        <li>
                          causes the Site or Platform to be used for commercial
                          or business purposes, including, without limitation,
                          advertising, marketing, or offering goods or services,
                          whether or not for financial or any other form of
                          compensation or through linking with any other Site or
                          web pages;
                        </li><br />
                        <li>
                          requests money from, or is intended to otherwise
                          defraud, us or other Users of the Site or Service;
                          and/or
                        </li><br />
                        <li>
                          impersonates, or otherwise misrepresents affiliation,
                          connection or association with, any person or
                          Business.
                        </li><br />
                      </ol>
                    </li>
                  </ol>
                </li><br />
                <li>
                  <b style={underline}>Fees; Third Party Payment Processor.</b><br /><br />
                  <ol>
                    <li>
                      <b>Fees.</b> Services are purchased as subscriptions (the
                      “Subscription”). Payment of Fees, as applicable, for the
                      use of the Services are as set forth on the Site and we
                      may bill any Fees to you through your Account or through a
                      Processor (as defined herein). You agree to pay us all
                      Fees at the prices you agreed to for any use of the
                      Service by you or other persons (including your agents)
                      using your Account. If we charge your Credit Card for Fees
                      and you initiate a chargeback or otherwise reverse a
                      payment made with your Credit Card, we may in our
                      discretion terminate your Account immediately. If we
                      successfully dispute the reversal, and the reversed funds
                      are returned, you are not entitled to a refund or to have
                      your Account reinstated.{" "}
                    </li><br />
                    <li>
                      <b>Third Party Payment Process.</b> You understand that we
                      may use a third-party service provider (the “Processor”)
                      (e.g., Stripe, PayPal, Recurly, etc.) for payment services
                      (e.g., credit card transaction processing, merchant
                      settlement, and any related service fees) to process the
                      Fees. By using the Site, Platform or Services, you agree
                      to be bound by Processor’s Terms of Use and Processor’s
                      Privacy Policy. You hereby consent to provide and
                      authorize us and Processor to share any information and
                      payment instructions you provide to the extent required to
                      complete the payment transactions in accordance with this
                      Agreement, including personal, financial, credit card
                      payment, and transaction information. YOU REPRESENT AND
                      WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT
                      CARD(S) OR OTHER PAYMENT MEANS USED TO PAY ANY FEE OR
                      CHARGE. By providing us or Processor with your payment
                      information, you agree that Processor is authorized to
                      immediately invoice you for all fees and charges due and
                      payable to us hereunder and that no additional notice or
                      consent is required. You agree to immediately notify us
                      and Processor (as applicable) of any change to your
                      payment information. You will be responsible for paying
                      any applicable taxes relating to your payments and credits
                      received and will indemnify and hold harmless the Company,
                      our Affiliates, and Processor from any and all taxes,
                      including sales tax, based on any payments made or
                      received by you in connection with the Services.
                    </li><br />
                  </ol>
                </li>
              </ol>
            </li><br />
            <li>
              <b>FEEDBACK.</b> You may provide us with comments, testimonials,
              ratings and feedback through the Site or Platform (collectively,
              the “Feedback”). By providing Feedback, you automatically grant us
              a perpetual, royalty-free, non-exclusive, irrevocable,
              unrestricted, worldwide license to use, copy, sublicense,
              reproduce, distribute, redistribute, modify, adapt, publish, edit,
              translate, transmit, create derivative works of, publish and/or
              broadcast, publicly perform or display any materials or other
              information (including without limitation, ideas contained therein
              for new or improved products or services) you submit to us. You
              further acknowledge and agree that your name and/or likeness may
              be associated with your Feedback and posted on the Site or
              Platform, and you hereby grant us a perpetual, royalty-free,
              non-exclusive, irrevocable, unrestricted, worldwide license to use
              your name and/or likeness in association with your Feedback. You
              agree that you shall have no recourse against us for any alleged
              or actual infringement or misappropriation of any proprietary
              right in your communication to us. You further acknowledge and
              agree that no compensation will be paid with respect to the use of
              your Feedback, as provided herein, and that we may remove any
              Feedback we post on the Site or Platform at any time in our sole
              discretion. Further, when you provide Feedback, you authorize and
              direct us to make such copies thereof as we deem necessary in
              order to facilitate the posting and storage of such content on the
              Site.
            </li><br />
            <li>
              <b>OPERATION.</b> We reserve complete and sole discretion with
              respect to the operation of the Site and the Platform. We may,
              among other things: (a) delete email or private messages or
              private notes if it has not been accessed by a User within the
              time established by our policies; (b) subject to Section 1, make
              available to third parties information relating to the Users; and
              (c) withdraw, suspend or discontinue any functionality or feature
              of the Site and Platform. We may, in our complete and sole
              discretion, review uploaded files, conferences, forums, and chats
              and authorize restrictions on access thereto. We will not review
              the contents of email or private messages except as required or
              allowed by applicable law or legal process or as we deem
              necessary, in our sole discretion, to ensure compliances with the
              terms of this Agreement and/or to protect the safety of our Users,
              including, but not limited to, reviewing private messages in
              response to a complaint made against, or a report submitted to us
              against, a User. You acknowledge and agree that from time to time
              the Service may be inaccessible or inoperable for any reason,
              including, without limitation: (x) equipment malfunctions; (y)
              periodic maintenance procedures or repairs which we may undertake
              from time to time; or (z) causes beyond our control or which are
              not reasonably foreseeable by us.
            </li><br />
            <li>
              <b>CONTENT AND GENERAL DISCLAIMERS</b><br /><br />
              <ol>
                <li>
                  <b style={underline}>General Disclaimer.</b> THE SITE AND PLATFORM ARE PROVIDED
                  BY US ON AN "AS IS" BASIS. WE MAKE NO REPRESENTATIONS OR
                  WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                  OPERATION OF THE SITE OR PLATFORM OR THE INFORMATION, CONTENT,
                  MATERIALS, OR PRODUCTS INCLUDED ON THE SITE OR PLATFORM. TO
                  THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL
                  WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE AND NON-INFRINGEMENT, AND WARRANTIES THAT
                  ACCESS TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED OR
                  ERROR-FREE. THERE ARE NO WARRANTIES THAT EXTEND BEYOND THE
                  FACE OF THESE TERMS. WE CANNOT AND DO NOT WARRANT AGAINST
                  HUMAN AND MACHINE ERRORS, OMISSIONS, DELAYS, INTERRUPTIONS OR
                  LOSSES, INCLUDING LOSS OF DATA. WE CANNOT AND DO NOT GUARANTEE
                  OR WARRANT THAT FILES AVAILABLE FOR DOWNLOADING FROM THE SITE
                  OR PLATFORM WILL BE FREE OF INFECTION BY VIRUSES, WORMS,
                  TROJAN HORSES OR OTHER CODE THAT MANIFEST CONTAMINATING OR
                  DESTRUCTIVE PROPERTIES. WE DO NOT WARRANT OR GUARANTEE THAT
                  THE FUNCTIONS OR SERVICES ACCESSED THROUGH THE SITE OR
                  PLATFORM OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE OR
                  THAT DEFECTS IN THE SITE, PLATFORM OR SERVICES WILL BE
                  CORRECTED. THIS DISCLAIMER OF WARRANTY CONSTITUTES AN
                  ESSENTIAL PART OF THESE TERMS. IF YOU ARE DISSATISFIED WITH
                  ANY PORTION OF THE SERVICES, OR WITH ANY OF THESE TERMS, YOUR
                  SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
                  SERVICES. WE MAY CHANGE THE SERVICES OR THE FEATURES IN ANY
                  WAY, AND AT ANY TIME AND FOR ANY REASON. ALTHOUGH WE HAVE
                  ATTEMPTED TO PROVIDE ACCURATE INFORMATION ON THE SITE AND
                  PLATFORM, WE ASSUME NO RESPONSIBILITY FOR THE ACCURACY OR
                  COMPLETENESS OF THE INFORMATION.
                </li><br />
                <li>
                  <b style={underline}>Informational Purposes Only.</b> Any opinions or Feedback
                  expressed on the Site or Platform are the personal opinions of
                  the original author and not ours, even though the original
                  author may be employed by us. The Content is provided for
                  informational and entertainment purposes only and is not an
                  endorsement or representation by us or any other party. We do
                  not assume any responsibility or liability for any Feedback,
                  opinion or other commentary posted on the Site or Platform or
                  any third party website linked to the Site or Platform and
                  make no express or implied warranty or guarantee about the
                  accuracy, copyright compliance, legality, or any other aspect
                  of the Content.{" "}
                </li><br />
                <li>
                  <b style={underline}>Disclaimer of Third Party Information. </b> You understand
                  that when using the Site or Platform, you may be exposed to
                  third party content from a variety of sources, and that we are
                  not responsible for the accuracy, usefulness, safety, or
                  intellectual property rights of or relating to such third
                  party content. You further understand and acknowledge that you
                  may be exposed to third party content that may be offensive,
                  indecent, inaccurate, or objectionable, and you agree to
                  waive, and hereby do waive, any legal or equitable rights or
                  remedies you have or may have against us with respect thereto.
                </li><br />
                <li>
                  <b style={underline}>Links to Third Party Sites.</b> The Site and Platform may
                  contain links to third party websites. You agree that access
                  to any other Internet Site linked to the Site or Platform is
                  done at your own risk and the Company is not responsible for
                  the accuracy, availability or reliability of any information,
                  goods, data, opinions, advice or statements made available on
                  these other websites. You understand these links may lead
                  unintentionally to websites containing information that you or
                  others may find inappropriate or offensive. These links are
                  not under the control of the Company and as such, you agree
                  that the Company is not liable for any damage or loss caused
                  or alleged to be caused by or in connection with the use of or
                  reliance on any such third party websites. These links are
                  provided by the Company merely for convenience and the
                  inclusion of these links does not imply an endorsement or
                  recommendation by the Company. You agree that the Company is
                  not responsible for any form of transmission received from any
                  link, nor is the Company responsible if any of these links are
                  not working appropriately. It is your responsibility when
                  viewing to abide by any privacy statements and terms of use
                  posted in connection with these links.{" "}
                </li><br />
              </ol>
            </li><br />
            <li>
              <b>INDEMNIFICATION.</b> YOU AGREE TO INDEMNIFY, DEFEND AND HOLD
              HARMLESS NUDGE AND OUR OFFICERS, DIRECTORS, EMPLOYEES, LICENSORS,
              AGENTS, INFORMATION PROVIDERS AND SUPPLIERS (COLLECTIVELY, OUR
              “AFFILIATES”) FROM AND AGAINST ALL LOSSES, EXPENSES, DAMAGES AND
              COSTS, INCLUDING REASONABLE ATTORNEY'S FEES, RESULTING FROM ANY
              VIOLATION OF THIS AGREEMENT, BREACH OF YOUR REPRESENTATIONS
              CONTAINED HEREIN, OR ANY ACTIVITY RELATED TO YOUR ACCOUNT
              (INCLUDING INFRINGEMENT OF THIRD PARTIES' WORLDWIDE INTELLECTUAL
              PROPERTY RIGHTS OR NEGLIGENT OR WRONGFUL CONDUCT) BY YOU OR ANY
              OTHER PERSON ACCESSING THE SITE, PLATFORM OR SERVICES USING YOUR
              ACCOUNT.
              <li>
                <b>WAIVER AND RELEASE.</b> YOU AGREE THAT NUDGE AND OUR
                AFFILIATES SHALL NOT HAVE ANY LIABILITY TO YOU UNDER ANY THEORY
                OF LIABILITY OR INDEMNITY IN CONNECTION WITH YOUR USE OF THE
                SITE, THE PLATFORM, THE SERVICES, OR THE CONTENT. YOU
                SPECIFICALLY ACKNOWLEDGE THAT WE SHALL NOT BE LIABLE FOR THE
                DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND
                THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
                ENTIRELY WITH YOU. YOU HEREBY RELEASE AND FOREVER WAIVE ANY AND
                ALL CLAIMS YOU MAY HAVE AGAINST NUDGE AND OUR AFFILIATES
                (INCLUDING BUT NOT LIMITED TO CLAIMS BASED UPON OUR NEGLIGENCE
                OR OUR AFFILIATES) FOR LOSSES OR DAMAGES YOU SUSTAIN IN
                CONNECTION WITH YOUR USE OF THE SITE, THE PLATFORM, THE
                SERVICES, OR THE CONTENT.
              </li><br />
              <li>
                <b>LIABILITY LIMITATION.</b> NOTWITHSTANDING THE FOREGOING
                PARAGRAPH, NUDGE AND OUR AFFILIATES WILL NOT BE LIABLE UNDER ANY
                THEORY OF LAW, FOR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
                SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING, ARISING OUT OF,
                BASED ON, OR RESULTING FROM THIS AGREEMENT OR YOUR USE OF THE
                SITE, THE PLATFORM OR SERVICES, INCLUDING, BUT NOT LIMITED TO,
                LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION OR
                DATA OR COSTS OF REPLACEMENT GOODS, ARISING OUT OF THE USE OR
                INABILITY TO USE THE SITE, THE PLATFORM, THE SERVICES OR
                RESULTING FROM USE OF OR RELIANCE ON THE INFORMATION PRESENTED,
                OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, SERVICE OR
                PLATFORM, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                LIABILITY OR OTHERWISE, EVEN IF THE COMPANY MAY HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME
                STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                LIMITATION MAY NOT APPLY TO YOU.
              </li><br />
              <li>
                <b>
                  COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT NOTIFICATION.
                </b>{" "}
                We respect the intellectual property rights of others. You can
                notify us of possible copyright infringement, and we will review
                all claims of copyright infringement received and remove content
                deemed to have been posted or distributed in violation of any
                such laws. To make a claim, please provide the following:<br /><br />
                <ol>
                  <li>
                    A physical or an electronic signature of the person
                    authorized to act on behalf of the owner of the copyright or
                    other intellectual property interest that is allegedly
                    infringed;{" "}
                  </li><br />
                  <li>
                    A description of the copyrighted work or other intellectual
                    property that you claim has been infringed;{" "}
                  </li><br />
                  <li>
                    A description of where the material that you claim is
                    infringing is located on the Site reasonably sufficient to
                    permit us to locate the material;
                  </li><br />
                  <li>
                    Your contact information, including your address, telephone
                    number, and email;
                  </li><br />
                  <li>
                    A statement by you that you have a good faith belief that
                    use of the material in the manner complained of is not
                    authorized by the copyright owner, its agent, or the law;
                    and
                  </li><br />
                  <li>
                    A statement by you that the above information in your notice
                    is accurate and that you, made under penalty of perjury, are
                    the copyright or intellectual property owner or authorized
                    to act on the copyright or intellectual property owner's
                    behalf.{" "}<br /><br />
                  </li>
                </ol>
                Contact the agent designated to receive and act on copyright
                violations under the Digital Millennium Copyright Act ("DMCA").
                Claims can be directed to us at (669) 285 - 9142 or to Musicbox,
                Inc., dba Nudge AI, 333 Harrison Street, #514, San Francisco, CA
                94105.<br /><br />
              </li>
            </li>
            <li>
              <b>TERM AND TERMINATION.</b> Either you or we may terminate this
              Agreement at any time and for any reason. In addition, this
              Agreement will terminate immediately, without notice, if you fail
              to comply with the terms of this Agreement. We also reserve the
              right to terminate or suspend your Account and access to the Site,
              Platform and Services without prior notice. The provisions of
              Sections 1.1 (Proprietary Rights), 1.3 (User Restrictions), 1.4
              (User Agreement), 1.5 (User Representations), 2 (Feedback), 4
              (Content and General Disclaimers), 5 (Indemnification), 6 (Waiver
              and Release), 7 (Limitation of Liabilities), 9 (Term and
              Termination), and 12 (Miscellaneous) shall survive any termination
              of this Agreement.
              <br /><br />
            </li>
            <li>
              <b>PRIVACY RIGHTS.</b> We are firmly committed to protecting your
              privacy, the privacy of Users, and the information that we gather.
              In using the Services, you represent and agree that you will not
              use the Services to track or collect personally identifiable
              information of Users. For our complete policy on privacy, please
              visit and review the Privacy Policy at
              https://getnudgeai.com/privacy-policy.
              <br /><br />
            </li>
            <li>
              <b>Export Controls. </b> You shall comply with all export laws and
              restrictions and regulations of the Department of Commerce, the
              United States Department of Treasury Office of Foreign Assets
              Control (“OFAC”), or other United States or foreign agency or
              authority, and not to export, or allow the export or re-export of
              the Material in violation of any such restrictions, laws or
              regulations. By downloading or using the Material, you are
              agreeing to the foregoing, and you are representing and warranting
              that you are not located in, under the control of, or a national
              or resident of any restricted country or on any such list.
              <br /><br />
            </li>
            <li>
              <b>MISCELLANEOUS. </b> This Agreement shall be governed by and
              construed in accordance with the laws of the State of California.
              You agree that any legal action or proceeding between us and you
              for any purpose concerning this Agreement or the parties'
              obligations hereunder shall be brought exclusively in a court of
              competent jurisdiction sitting in San Francisco County,
              California, United States. Any cause of action or claim you may
              have with respect to us must be commenced within one (1) year
              after the claim or cause of action arises. Our failure to insist
              upon or enforce strict performance of any provision of this
              Agreement shall not be construed as a waiver of any provision or
              right. No waiver of any term, provision or condition of this
              Agreement, whether by conduct or otherwise, in any one or more
              instances, shall be deemed to be, or shall constitute, a waiver of
              any other term, provision or condition hereof, whether or not
              similar, nor shall such waiver constitute a continuing waiver of
              any such term, provision or condition hereof. No waiver shall be
              binding unless executed in writing by the party making the waiver.
              This Agreement and the Privacy Policy, constitutes the complete
              and exclusive statement of the agreement between the parties with
              respect to the Services and supersedes any and all prior or
              contemporaneous communications, representations, statements and
              understandings, whether oral or written, between the parties
              concerning the Services. If any action in law or in equity is
              necessary to enforce the terms of this Agreement or the Privacy
              Policy, the prevailing party will be entitled to reasonable fees
              of attorneys, accountants, and other professionals, and costs and
              expenses in addition to any other relief to which such prevailing
              party may be entitled. The provisions of this Agreement are
              severable, and in the event any provision hereof is determined to
              be invalid or unenforceable, such invalidity or unenforceability
              shall not in any way affect the validity or enforceability of the
              remaining provisions hereof. No provisions of this Agreement are
              intended, nor will be interpreted, to provide or create any third
              party beneficiary rights or any other rights of any kind in any
              Nonprofit User, client, customer, affiliate, or any party hereto
              or any other person unless specifically provided otherwise herein,
              and except as so provided, all provisions hereof will be personal
              solely between the parties to this Agreement; except that Sections
              5, 6, and 7 are intended to benefit us and our officers,
              directors, employees, agents, licensors, and suppliers. We may
              assign our rights and duties under this Agreement to any party at
              any time without notice to you.<br /><br />
            </li>
            <li>
              <b>NOTICE.</b> We may deliver notice to you under this Agreement
              by means of electronic mail, a general notice on the Site or
              through the Platform, or by written communication delivered by
              first class U.S. mail to your address on record in the Account.
              You may give notice to us at any time via electronic mail to the
              Site at the following address: contact@getnudgeai.com. Electronic
              is preferred, but you can also direct physical correspondence to:
              <br />
              <br />
              <br />
              Musicbox, Inc.,dba Nudge AI<br /> 333 Harrison Street, #514 <br />San
              Francisco,CA 94105
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
