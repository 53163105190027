import { Col } from "antd";
import { Select, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import styles from "./createnotemodal.module.scss";

const LocationSelector = () => {
  const componentName = "createnotemodal";
  const dispatch = useAppDispatch();

  const { recordingDetail } = useAppSelector((state) => state.recordings);
  return (
    <Col span={12} className={`${styles[`${componentName}__modal-row`]}`}>
      <Typography.Text>Location: (optional)</Typography.Text>
      <Select
        value={recordingDetail?.location_type}
        placeholder="Select Location"
        onChange={(value) => {
          dispatch(
            setRecordingDetail({
              ...recordingDetail,
              location_type: value,
            })
          );
        }}
      >
        <Select.Option value={undefined}>---</Select.Option>
        <Select.Option value={0}>Telehealth</Select.Option>
        <Select.Option value={1}>In-person</Select.Option>
      </Select>
    </Col>
  );
};

export default LocationSelector;
