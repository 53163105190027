import { LoadingOutlined } from "@ant-design/icons";
import { Card, Flex, Skeleton } from "antd";
import React from "react";
import { getNotesType } from "../../domain/notes.domain";
import { useAppSelector } from "../../hooks/redux-hooks";
import { handleGenerateTranscriptOfNote } from "../../service/notes.service";
import {
  EventType,
  trackEvent
} from "../../utils/analytics";
import styles from "./notes.module.scss";

const TranscriptView = () => {
  const componentName = "notes";

  const [generatingTranscript, setGeneratingTranscript] =
    React.useState<boolean>(true);
  const [transcriptContent, setTranscriptContent] = React.useState<string>("");

  const { notesDetails } = useAppSelector((state) => state.notes);

  const handleGenerateTranscript = async () => {
    if (notesDetails) {
      trackEvent(EventType.GENERATE_TRANSCRIPT, {
        recordingId: notesDetails?.id,
        noteType: getNotesType(notesDetails.note_type),
      });

      if (transcriptContent) {
        return;
      }

      handleGenerateTranscriptOfNote(notesDetails?.id)
        .then((result) => {
          setTranscriptContent(result.data.transcript);
        })
        .catch((error) => {
          console.error(error);
          setGeneratingTranscript(false);
        })
        .finally(() => {
          setGeneratingTranscript(false);
        });
    }
  };

  // biome-ignore lint: no need to add transcriptContent to the dependency array
  React.useEffect(() => {
    if (!transcriptContent) {
      handleGenerateTranscript();
    } else {
      setGeneratingTranscript(false);
    }
  }, [notesDetails]);

  return (
    <>
      <Flex vertical gap={20}>
        <Flex vertical>
          {generatingTranscript ? (
            <>
              <Flex>
                <LoadingOutlined /> &nbsp;{" "}
                <span>Generating transcript content</span>
              </Flex>
              <Skeleton active />
            </>
          ) : (
            <Card
              hoverable
              className={`${styles[`${componentName}__main-section`]}`}
              style={{
                textAlign: "left",
              }}
            >
              {transcriptContent ? (
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {transcriptContent}
                </div>
              ) : (
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  No Transcript available.
                </div>
              )}
            </Card>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default TranscriptView;
