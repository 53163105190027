import { Typography } from "@mui/material";
import { Avatar, Col, Flex } from "antd";
import SideBottomImage from "../../assets/bg/side_bottom.png";
import feature from "../../constants/data";
import styles from "./auth.module.scss";

export default function RightSideAuth() {
  const componentName = "login";
  return (
    <Col
      sm={24}
      md={24}
      lg={12}
      className={`${styles[`${componentName}__side`]} primary-bg`}
    >
      <Typography
        variant="h2"
        className={`${styles[`${componentName}__side-title`]} primary-bg`}
      >
        Why nudge?
      </Typography>
      {feature.map((item) => (
        <Flex className={`${styles[`${componentName}__side-feature`]}`}>
          <Avatar
            shape="square"
            size={44}
            icon={item.icon}
            className={`${styles[`${componentName}__side-avatar`]}`}
          />
          <Flex vertical justify="space-between">
            <Typography
              variant="h5"
              className={`${styles[`${componentName}__side-feature-title`]}`}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              className={`${styles[`${componentName}__side-feature-description`]}`}
            >
              {item.description}
            </Typography>
          </Flex>
        </Flex>
      ))}
      <img
        src={SideBottomImage}
        width={"500px"}
        alt="Nudge Feature"
        className={styles[`${componentName}__side-image`]}
      />
    </Col>
  );
}
