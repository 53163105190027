import { Typography } from "@mui/material";
import { Button, Col, Flex, Form, Input, Row, Divider, Card } from "antd";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import HippaLogo from "../../assets/bg/hippa.png";
import LoadingModal from "../../components/LoadingModal";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { setIsDemoPath } from "../../slices/appStateSlice";
import { login } from "../../slices/authSlice";
import { fetchUserData } from "../../slices/userSlice";
import { EventType, identifyUser, trackEvent } from "../../utils/analytics";
import styles from "./auth.module.scss";
import axiosInstance from "../../api/axiosInstance";
import { useRootPath } from "../../hooks/app.hook";
import MarketingCopy from './MarketingCopy';

const Login = () => {
  const componentName = "login";
  const [form] = Form.useForm();
  const { rootPath } = useRootPath();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    const { email, password } = form.getFieldsValue();
    setIsLoading(true);
    if (email && password) {
      try {
        const loginSuccessful = await dispatch(
          login({
            email,
            password,
          })
        ).unwrap();

        if (loginSuccessful) {
          dispatch(setIsDemoPath(false));
          dispatch(fetchUserData());
          identifyUser(email, { email });
          trackEvent(EventType.LOGIN, { email });
          const token = localStorage.getItem("token");
          if (!token) {
            setIsLoading(false);
            return;
          }
          navigate(rootPath);
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        // replace below alet with sweetalert2 alert
        Swal.fire({
          title: "Incorrect email or password",
          icon: "error",
          text: "Incorrect email or password. Please try again.",
        });
      }
    } else {
      console.log("Email and password are required");
      setIsLoading(false);
      Swal.fire({
        title: "Password cannot be blank",
        icon: "error",
        text: "Please enter your password.",
      });
    }
  };

  const handleGoogleLogin = async (credentialResponse: any) => {
    const decoded: any = jwtDecode(credentialResponse.credential);

    try {
      const response = await axiosInstance.post("/accounts/google/login/", {
        access_token: credentialResponse.credential,
        id_token: credentialResponse.credential,
      });

      if (response.data.key) {
        localStorage.setItem("token", response.data.key);
        dispatch(setIsDemoPath(false));
        dispatch(fetchUserData());
        identifyUser(decoded.email, { email: decoded.email });
        trackEvent(EventType.GOOGLE_SIGN_IN, { email: decoded.email });
        navigate(rootPath);
      }
    } catch (error) {
      console.error("Google login error:", error);
      Swal.fire({
        title: "Login Error",
        icon: "error",
        text: "We couldn't sign you in with Google. If you originally signed up with an email and password, please use those to sign in. If you're new here, please sign up first.",
      });
    }
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Row className="fullScreen auth-container">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={styles.marketingSection}
        >
          <MarketingCopy />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className={styles.formSection}>
          <Card className={styles.formCard}>
            <Flex vertical justify="center" align="center">
              <Typography variant="h5" className={styles.formTitle}>
                Welcome back to Nudge
              </Typography>
              <div
                style={{
                  marginTop: 15,
                  boxSizing: "border-box",
                }}
              >
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  size="large"
                  text="signin_with"
                  onError={() => {
                    console.error("Google Sign In Failed");
                    Swal.fire({
                      title: "Sign In Error",
                      icon: "error",
                      text: "We couldn't sign you in with Google. If you originally signed up with an email and password, please use those to sign in. If you're new here, please sign up first.",
                    });
                  }}
                />
              </div>
              <Divider style={{ width: "100%", margin: "10px 0" }}>OR</Divider>
              <Form
                className={styles.formContainer}
                form={form}
                layout="vertical"
                onFinish={handleLogin}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please input your Email" },
                  ]}
                >
                  <Input placeholder="John@example.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    placeholder="input password"
                    type="password"
                  />
                </Form.Item>
                <Flex justify="space-between" className={styles.formLinks}>
                  <NavLink to={"/forgot-password"}>Forgot Password?</NavLink>
                  <NavLink to={"/register"}>New User? Register now</NavLink>
                </Flex>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <div
                className={
                  styles[`${componentName}__form-container-bottom-notification`]
                }
              >
                Your data is protected with HIPAA-compliant encryption. <br />{" "}
                Nudge removes any personally identifying data, including audio.
              </div>
              <img
                alt="Hippa"
                src={HippaLogo}
                className={styles[`${componentName}__side-image-hippa`]}
              />
            </Flex>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
