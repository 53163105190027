import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
  token: {
    // Seed Token
    colorPrimary: "#6366F1",
    colorTextLabel: "#374151",
    colorBorder: "#E5E7EB",
  },
  components: {
    Input: {
      activeShadow: "0px 1px 2px 0px #0000000D",
      boxShadow: "0px 1px 2px 0px #0000000D",
      paddingBlock: 9,
      paddingInline: 13,
    },
    Button: {
      paddingBlock: 0,
    },
    Form: {
      itemMarginBottom: 14,
      labelColor: "#374151",
    },
    Collapse: {
      headerBg: "transparent",
    },
  },
};
