import { Button } from "antd";
import styles from "./notes.module.scss";
import { CopyOutlined } from "@ant-design/icons";

const NoteCopyButton = ({ onClick }: { onClick: () => void }) => {
  const componentName = "notes";
  return (
    <Button
      type="default"
      className={`${styles[`${componentName}__main-section-copy-button`]}`}
      onClick={onClick}
      icon={<CopyOutlined />}
    >
      Copy
    </Button>
  );
};


export default NoteCopyButton;