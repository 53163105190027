import { Navigate, Outlet } from "react-router-dom";

const DefaultLayout = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/home" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultLayout;
