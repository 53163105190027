import axiosInstance from "../api/axiosInstance";

type TCheckoutBody = {
  final_price: number;
  billing_period: string;
  tier_type: string;
  seats: number;
};

const handleCheckoutSevice = (body: TCheckoutBody) => {
  return axiosInstance.post("/users/plink/", body);
};

export { handleCheckoutSevice };
