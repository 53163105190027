import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Flex, message, Popover, Typography } from "antd";
import React from "react";
import useCheckDemoPath from "../../hooks/app.hook";
import { useAppSelector } from "../../hooks/redux-hooks";
import {
  generateSessionInsight,
  getNotesDetails,
} from "../../service/notes.service";
import { EventType, NoteSection, trackEvent } from "../../utils/analytics";
import { MAX_POILING_TIME_OUT } from "../../utils/constants";
import { copySectionContent } from "../../utils/datamanipulation.utils";
import styles from "./notes.module.scss";

const NotesSessionInsight = () => {
  const componentName = "notes";
  const checkDemoPath = useCheckDemoPath();
  const [isOldInsights, setIsOldInsights] = React.useState(false);
  const [sessionInsightsContent, setSessionInsightsContent] =
    React.useState("");
  const [expandedSessionInsights, setExpandedSessionInsights] =
    React.useState(false);

  const { notesDetails } = useAppSelector((state) => state.notes);

  const handleGenerateNewInsights = async () => {
    if (checkDemoPath()) {
      return;
    }
    trackEvent(EventType.GENERATE_SESSION_INSIGHTS, {
      recordingId: notesDetails?.id,
    });

    setIsOldInsights(false);
    generateSessionInsight(notesDetails?.id)
      .then((result) => {
        setSessionInsightsContent(result.data["session_insights"]);
      })
      .catch((error) => {
        console.error(error);
        setIsOldInsights(true);
        message.error("Error generating session insights. Please try again.");
      })
      .finally(() => {});
  };

  const pollSessionInsights = async () => {
    try {
      const response = await getNotesDetails(notesDetails?.id);
      const data = await response.data;
      if (data.session_insights) {
        setSessionInsightsContent(data.session_insights);
        return;
      }

      // If not done processing insights then do the poll.
      const currentDate = new Date();
      const genNoteEndDate = new Date(data.stats_generate_note_end_at);
      if (
        currentDate.getTime() - genNoteEndDate.getTime() <=
        MAX_POILING_TIME_OUT
      ) {
        setTimeout(() => pollSessionInsights(), 3000);
      } else {
        setIsOldInsights(true);
      }
    } catch (error) {
      console.error("Error checking audio status:", error);
    }
  };

  // biome-ignore lint: no need to add pollSessionTreatmentPlan to the dependency array
  React.useEffect(() => {
    const currentDate = new Date();
    const genNoteEndDate = notesDetails
      ? new Date(notesDetails.stats_generate_note_end_at)
      : null;
    const isWithinTimeout =
      genNoteEndDate &&
      currentDate.getTime() - genNoteEndDate.getTime() <= MAX_POILING_TIME_OUT;

    if (notesDetails?.id) {
      if (!notesDetails.session_insights) {
        pollSessionInsights();
      } else {
        setSessionInsightsContent(notesDetails.session_insights);
      }
    } else {
      setIsOldInsights(true);
    }
  }, [notesDetails]);

  if (!notesDetails) {
    return null;
  }

  return (
    <Flex
      style={{
        width: "100%",
        marginBottom: "20px",
      }}
      gap={10}
    >
      <Card
        id="special-card"
        hoverable
        title={
          <Flex align="center" gap={10}>
            Nudge Session Insights{" "}
          </Flex>
        }
        extra={
          <Popover
            placement="left"
            title={"Nudge Session Insights"}
            content={
              <>
                Nudge Insights is a powerful tool designed to assist
                <br />
                the clinician in gaining valuable insights post-session.
                <br />
                The tool is not meant to substitue for a clinician's
                <br />
                insights, but rather to assist the clinician with ideas.
                <br />
                <br />
                Nudge serves as an assistant to the clinician,
                <br />
                strengthening therapeutic alliance by aiding the
                <br />
                clinician in improving outcomes.
                <br />
              </>
            }
          >
            <span
              className={`${styles[`${componentName}__main-section-span`]}`}
              style={{ color: "grey" }}
            >
              (what is this?)
            </span>
          </Popover>
        }
        style={{ width: "100%" }}
      >
        {isOldInsights ? (
          <>
            <Flex vertical gap={10}>
              <Typography>
                This might be an old note. Would you like your Nudge assistant
                to generate session insights?
              </Typography>
              <Flex>
                <Button type="primary" onClick={handleGenerateNewInsights}>
                  Generate
                </Button>
              </Flex>
            </Flex>
          </>
        ) : (
          <>
            {!sessionInsightsContent ? (
              <Flex align="center">
                <LoadingOutlined />
                <Typography
                  style={{
                    marginLeft: "10px",
                    fontWeight: "bold",
                    color: "#606060",
                  }}
                >
                  Your Nudge assistant is generating session insights
                </Typography>
              </Flex>
            ) : (
              <Typography style={{ whiteSpace: "pre-line" }}>
                <Flex vertical gap={10}>
                  <Flex>
                    {expandedSessionInsights
                      ? sessionInsightsContent
                      : `${sessionInsightsContent
                          .split(" ")
                          .slice(0, 35)
                          .join(" ")}${
                          sessionInsightsContent.split(" ").length > 35
                            ? "..."
                            : ""
                        }`}
                  </Flex>
                  <Flex>
                    <Button
                      type={expandedSessionInsights ? "default" : "primary"}
                      onClick={() => {
                        if (!expandedSessionInsights) {
                          trackEvent(EventType.EXPAND_SESSION_INSIGHTS, {
                            recordingId: notesDetails?.id,
                          });
                        }
                        setExpandedSessionInsights(!expandedSessionInsights);
                      }}
                    >
                      {expandedSessionInsights ? "Show Less" : "Show More"}
                    </Button>{" "}
                    &nbsp;
                    {expandedSessionInsights && (
                      <Button
                        type="default"
                        className={`${
                          styles[`${componentName}__main-section-copy-button`]
                        }`}
                        onClick={() =>
                          copySectionContent(
                            notesDetails,
                            sessionInsightsContent,
                            NoteSection.NUDGE_INSIGHTS
                          )
                        }
                        icon={<CopyOutlined />}
                      >
                        Copy
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Typography>
            )}
          </>
        )}
      </Card>
    </Flex>
  );
};

export default NotesSessionInsight;
