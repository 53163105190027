import TextArea from "antd/es/input/TextArea";
import styles from "./notes.module.scss";
import { setNotesDetails } from "../../slices/notesSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

const NotesTextArea = ({ value, field, bigger = false }: { value: string; field: string, bigger?: boolean }) => {
  const dispatch = useAppDispatch();
  const { notesDetails } = useAppSelector((state) => state.notes);
  const componentName = "notes";
  if (!notesDetails) {
    return null;
  }
  return (
    <div className={`${styles[`${componentName}__main-section-description`]}`}>
      <TextArea
        className={`${styles[`${componentName}__main-section-textarea`]}`}
        value={value}
        autoSize={ bigger ? { minRows: 3, maxRows: 25 } :{ minRows: 3, maxRows: 6 }}
        onChange={(event) => {
          dispatch(
            setNotesDetails({
              ...notesDetails,
              [field]: event.target.value,
            })
          );
        }}
      />
    </div>
  );
};

export default NotesTextArea;
