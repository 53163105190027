import { Flex, Typography } from "antd";
import emptyStateClient from "../../assets/bg/no_client.svg";
const EmptyStateClient = () => {
  return (
    <Flex vertical align="center" justify="center">
      <img src={emptyStateClient} width={"50px"} alt="Empty State Client" />
      <Typography
        style={{
          color: "var(--secondary-text)",
          fontSize: "20px",
          marginTop: "10px",
        }}
      >
        Add clients here.
      </Typography>
      <Typography
        style={{
          color: "var(--grey-text)",
        }}
      >
        Secure & HIPAA-Compliant.
      </Typography>
    </Flex>
  );
};

export default EmptyStateClient;
