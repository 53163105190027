import { Typography } from "@mui/material";
import { Button, Col, Flex, Form, Input, Row, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import HippaLogo from "../../assets/bg/hippa.png";
import { EventType, trackEvent } from "../../utils/analytics";
import RightSideAuth from "./auth-right-side.component";
import styles from "./auth.module.scss";

export default function ForgetPassword() {
  const componentName = "login";
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleForgetPassword =
    ({ email }: { email: string }) => {
      if (!email) {
        message.error("Email is required");
        return;
      }

      //check if email is valid
      const emailRegex = new RegExp(/\S+@\S+\.\S+/);
      if (!emailRegex.test(email)) {
        message.error("Please enter a valid email address.");
        return;
      }

      trackEvent(EventType.FORGOT_PASSWORD, { email });

      const urlSuffix = "/users/password-reset/";
      const data = {
        email,
      };

      axiosInstance
        .post(urlSuffix, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("Status Code:", response.status);
          console.log("Response Data:", response.data);
          message.success(
            "An email has been sent to reset your password. Please check your email.",
          );
          navigate("/login");
        })
        .catch(function (error) {
          console.log("An error occurred:", error);
        });
  };
  return (
    <Row className="fullScreen">
      <Col
        span={24}
        md={24}
        lg={12}
        className={styles[`${componentName}__left`]}
      >
        <Flex
          className={styles[`${componentName}__form`]}
          vertical
          justify="center"
        >
          <Typography
            fontWeight={500}
            fontSize={30}
            variant="h5"
            textAlign={"center"}
            margin={4}
          >
            Forget Password
          </Typography>
          <Form
            className={styles[`${componentName}__form-container`]}
            form={form}
            layout="vertical"
            onFinish={handleForgetPassword}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your Email" }]}
            >
              <Input placeholder="John@example.com" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginTop: "10px" }}
              >
                Forget Password
              </Button>
            </Form.Item>
          </Form>
          <Flex
            className={styles[`${componentName}__form-container-text`]}
            justify="center"
          >
            Already User ? &nbsp;<NavLink to={"/login"}>Login</NavLink>
          </Flex>
          <div
            className={
              styles[`${componentName}__form-container-bottom-notification`]
            }
          >
            Nudge is HIPAA compliant and redacts PHI. <br />
            Nudge removes any personally identifying data, including audio.
          </div>
          <img
            src={HippaLogo}
            alt="HIPPA"
            className={styles[`${componentName}__side-image-hippa`]}
          />
        </Flex>
      </Col>
      <RightSideAuth />
    </Row>
  );
}
