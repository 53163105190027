export const GWUL_GROUP_NAME = "Greater Washington Urban League";
export const MAX_RECORDING_TIME_SECONDS = 10800;
export const SOL_GROP_NAME = "SOL Mental Health";
export const MAX_POILING_TIME_OUT = 2 * 60 * 1000;
export const LLT_GROUP_NAME = "Language Link Therapy"

// localStorage keys
export const RECORDING_WITH_HEADPHONES = "RECORDING_WITH_HEADPHONES";


export const INTERCOM_APP_ID = "lzzsoyz4"