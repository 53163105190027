import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { TClientForm, Tdsm } from "../types/index.type";

// Define a type for the slice state
type ClientState = {
  clients: TClientForm[];
  clientForm: TClientForm;
  dsm: Tdsm[];
  customizationList: any[];
  isEditing: boolean;
}

// Initial state
const initialState: ClientState = {
  clients: [],
  dsm: [],
  clientForm: {
    name: "",
    category_type: 0,
    gender_type: 0,
    language_type: 0,
    diagnosis_ids: [],
    refer_type: 0,
  },
  customizationList: [],
  isEditing: false,
};

// Slice
const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<TClientForm[]>) => {
      state.clients = action.payload;
    },
    setClientForm: (state, action: PayloadAction<TClientForm>) => {
      state.clientForm = action.payload;
    },
    setDsm: (
      state,
      action: PayloadAction<{ id: number; code: string; name: string }[]>,
    ) => {
      state.dsm = action.payload;
    },
    setCustomizationList: (state, action: PayloadAction<any[]>) => {
      state.customizationList = action.payload;
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    resetClientForm: (state) => {
      state.clientForm = { ...initialState.clientForm };
    },
  },
});

// Actions
export const {
  setClientForm,
  setClients,
  resetClientForm,
  setDsm,
  setIsEditing,
  setCustomizationList,
} = clientSlice.actions;

// Reducer
export default clientSlice.reducer;
