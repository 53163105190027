import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Flex,
  Input,
  InputRef,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import NoteTypeBadge from "../../components/Badge/NotesType.component";
import CategoryType from "../../components/Category/Category.component";
import GenderType from "../../components/Gender/GenderType.component";
import LocationType from "../../components/Location/LocationType.component";
import {
  extractPsychotherapyExplanation,
  getCptCodeLabelByPsychotherapyMinutes,
  getNotesType,
  NoteType,
  showAddonCptCode,
  totalTimeSpentOnPsychotherapy,
} from "../../domain/notes.domain";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { updateNote } from "../../service/notes.service";
import {
  setCreateModalState,
  setCustomiseSectionState,
  setEditingState,
  setSelectedNoteForEditing,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import { setNotesDetails } from "../../slices/notesSlice";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { TNotes } from "../../types/index.type";
import { EventType, NoteSection, trackEvent } from "../../utils/analytics";
import {
  copyNote,
  durationTextFromType,
  durationTimeFromType,
} from "../../utils/datamanipulation.utils";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./notes.module.scss";

type TProps = {
  notesDetails: any;
};

const CUSTOMISE_ICON = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33333 1V3.66667M1 2.33333H3.66667M3 10.3333V13M1.66667 11.6667H4.33333M7.66667 1L9.19048 5.57143L13 7L9.19048 8.42857L7.66667 13L6.14286 8.42857L2.33333 7L6.14286 5.57143L7.66667 1Z"
      stroke="#6366F1"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NotesHeader = ({ notesDetails }: TProps) => {
  const dispatch = useAppDispatch();
  const componentName = "notes";

  const titleRef = React.useRef<InputRef>(null);

  const { isDemoPath } = useAppSelector((state) => state.appState);

  const { emailContent, activeKey } = useAppSelector((state) => state.notes);

  const psychoTherapyMinutes = useMemo(() => {
    // TODO: Fix this on the backend
    if (notesDetails?.psychotherapy_minutes) {
      return notesDetails.psychotherapy_minutes;
    }
    if (notesDetails?.sol_psych_intake_note) {
      return totalTimeSpentOnPsychotherapy(notesDetails.sol_psych_intake_note);
    }
    return notesDetails?.sol_psych_followup_note
      ? totalTimeSpentOnPsychotherapy(notesDetails?.sol_psych_followup_note)
      : 0;
  }, [notesDetails]);

  const psychoTherapyExplanation = useMemo(() => {
    if (notesDetails?.psychotherapy_minutes_explanation) {
      return notesDetails.psychotherapy_minutes_explanation;
    }

    if (notesDetails?.sol_psych_intake_not) {
      return extractPsychotherapyExplanation(
        notesDetails?.sol_psych_intake_not
      );
    }

    return notesDetails?.sol_psych_followup_note
      ? extractPsychotherapyExplanation(notesDetails?.sol_psych_followup_note)
      : "";
  }, [notesDetails]);

  const [editTitle, setEditTitle] = React.useState(false);

  const copyAllContent = () => {
    if (!notesDetails) return;

    let copiedText = "";
    let addOnDetails = "";

    trackEvent(EventType.COPY_NOTE, {
      noteType: getNotesType(notesDetails.note_type),
      noteSection: NoteSection.ALL,
      recordingId: notesDetails?.id,
    });

    const addDetails = (detailsArray: string[]) =>
      detailsArray.join("\n\n") + "\n\n";

    if (
      notesDetails.location_type != null ||
      notesDetails.duration_type != null
    ) {
      const details = ["Session Details:"];
      if (notesDetails.location_type != null) {
        details.push(
          `Location: ${
            notesDetails.location_type === 0 ? "Telehealth" : "In-person"
          }`
        );
      }
      if (notesDetails.duration_type != null) {
        details.push(
          `CPT Code: ${durationTextFromType(notesDetails.duration_type)}`
        );
      }
      copiedText = addDetails(details);
    }

    if (showAddonCptCode(notesDetails)) {
      const details = ["Psychotherapy Add-On:"];
      details.push(
        `Add-On CPT Code: ${getCptCodeLabelByPsychotherapyMinutes(
          psychoTherapyMinutes,
          notesDetails
        )}`
      );
      if (psychoTherapyMinutes)
        details.push(`Psychotherapy Minutes: ${psychoTherapyMinutes}`);
      if (psychoTherapyExplanation)
        details.push(`Explanation: ${psychoTherapyExplanation}`);
      addOnDetails = addDetails(details);
    }

    copyNote(notesDetails, copiedText, addOnDetails);
  };

  const handleCopyEmail = () => {
    if (notesDetails) {
      trackEvent(EventType.COPY_CLIENT_EMAIL, {
        recordingId: notesDetails?.id,
        noteType: getNotesType(notesDetails.note_type),
      });
      navigator.clipboard.writeText(emailContent);
    }
    message.success("Email content copied to clipboard");
  };

  const setEditingMode = (value: boolean, notes: TNotes) => {
    dispatch(setEditingState(value));
    dispatch(setCreateModalState(true));
    dispatch(setSelectedNoteForEditing(notes));
    dispatch(
      setRecordingDetail({
        category_type: notes.category_type,
        language_type: notes.language_type,
        note_type: notes.note_type,
        title: notes.title,
        gender_type: notes.gender_type,
        modality_type: notes.modality_type.toString(),
        location_type: notes.location_type,
        duration_type: notes.duration_type,
        duration_addon_type: notes.duration_addon_type,
        client_id: notes.client,
      })
    );
  };

  const updateTitle = async () => {
    setEditTitle(false);
    const value = titleRef.current?.input?.value;
    if (value && notesDetails && value !== notesDetails?.title) {
      const updateNoteData = {
        audio_id: notesDetails.id,
        title: value,
      };
      await updateNote(updateNoteData)
        .then(() => {
          message.success("Title updated successfully");
          dispatch(
            setNotesDetails({
              ...notesDetails,
              title: value,
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const CustomiseButton = () => {
    if (notesDetails.note_type === 0 || notesDetails.note_type === 2)
      return (
        <Button
          type="default"
          icon={CUSTOMISE_ICON}
          className={`mobile-action-button primary`}
          onClick={() => {
            dispatch(setCustomiseSectionState(true));
          }}
        >
          Customize
        </Button>
      );
    return <></>;
  };

  const EditButton = () => {
    return (
      <Button
        type="default"
        icon={<EditOutlined />}
        className={`${
          styles[`${componentName}__main-section-header-edit`]
        } mobile-action-button`}
        onClick={() => {
          setEditingMode(true, notesDetails);
        }}
      >
        Edit
      </Button>
    );
  };

  const CopyButton = () => {
    return (
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={() => {
          activeKey === "2" ? handleCopyEmail() : copyAllContent();
        }}
        className="mobile-action-button"
      >
        {`Copy ${activeKey === "2" ? "Email" : "Note"}`}
      </Button>
    );
  };

  const MidDot = () => {
    return <span>&middot;</span>;
  };

  return (
    <>
      {notesDetails && notesDetails.client_name && (
        <Flex
          justify="space-between"
          gap={10}
          style={{
            marginBottom: "10px",
          }}
        >
          <Flex
            gap={10}
            align="center"
            className={`${
              styles[`${componentName}__main-section-header-container`]
            }`}
          >
            <Avatar
              size={"small"}
              style={{
                backgroundColor: getRandomAvatarColor(notesDetails.client_name),
              }}
            >
              {notesDetails.client_name[0].toUpperCase()}
            </Avatar>
            <Typography>{notesDetails.client_name}</Typography>
          </Flex>
          <div
            className={`${
              styles[`${componentName}__main-section-header-action`]
            }`}
          >
            <CustomiseButton />
            <EditButton />
            <CopyButton />
          </div>
        </Flex>
      )}
      <Flex
        gap={10}
        justify="space-between"
        className={`${
          styles[`${componentName}__main-section-title-container`]
        }`}
        align={"flex-start"}
      >
        <span className={`${styles[`${componentName}__main-section-header`]}`}>
          {editTitle ? (
            <Input
              defaultValue={notesDetails?.title || "Untitled Session"}
              ref={titleRef}
              onBlur={updateTitle}
            />
          ) : (
            <span title={notesDetails?.title || "Untitled Session"}>
              {notesDetails?.title || "Untitled Session"}
            </span>
          )}
          {!editTitle ? (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              className={`${
                styles[`${componentName}__main-section-header-edit`]
              }`}
              onClick={() => {
                if (isDemoPath) {
                  dispatch(setShowDemoSignupModal(true));
                  return;
                }
                setEditTitle(true);
              }}
            />
          ) : (
            <Button
              shape="circle"
              type="primary"
              className={`${
                styles[`${componentName}__main-section-header-save`]
              }`}
              icon={<CheckOutlined />}
              onClick={updateTitle}
            />
          )}
        </span>
        {!(notesDetails && notesDetails.client_name) && (
          <div
            className={`${
              styles[`${componentName}__main-section-header-action`]
            }`}
          >
            <CustomiseButton />
            <EditButton />
            <CopyButton />
          </div>
        )}
      </Flex>
      <Flex
        className={`${styles[`${componentName}__main-detail`]}`}
        gap={5}
        align="center"
      >
        <span>
          <CalendarOutlined /> &nbsp;
          {dayjs(notesDetails?.uploaded_at).format("MMM D, YYYY")} at{" "}
          {dayjs(notesDetails?.uploaded_at).format("h:mm A")}
        </span>

        {notesDetails.duration_type !== null &&
          notesDetails.duration_type !== undefined && (
            <>
              <MidDot />
              <span>
                <ClockCircleOutlined />
                &nbsp;
                {durationTimeFromType(notesDetails.duration_type)}
              </span>
            </>
          )}
        <MidDot />
        <CategoryType category_type={notesDetails?.category_type} />
        <MidDot />
        <NoteTypeBadge note_type={notesDetails?.note_type} />
        <MidDot />
        <GenderType gender_type={notesDetails?.gender_type} />
        {notesDetails?.location_type !== undefined &&
          notesDetails?.location_type !== null && (
            <>
              <MidDot />
              <LocationType location_type={notesDetails?.location_type} />
            </>
          )}
      </Flex>
      <Flex
        className={`${styles[`${componentName}__main-detail-mobile`]}`}
        gap={10}
        vertical
      >
        <Flex gap={10}>
          <span>
            <CalendarOutlined /> &nbsp;
            {dayjs(notesDetails?.uploaded_at).format("MMM D, YYYY")} at{" "}
            {dayjs(notesDetails?.uploaded_at).format("h:mm A")}
          </span>

          {notesDetails?.duration_type !== undefined &&
            notesDetails?.duration_type !== null && (
              <>
                <MidDot />
                <span>
                  <ClockCircleOutlined />
                  &nbsp;
                  {durationTimeFromType(notesDetails.duration_type)}
                </span>
              </>
            )}
        </Flex>
        <Flex gap={10} wrap="wrap">
          <CategoryType category_type={notesDetails?.category_type} />
          <MidDot />
          <NoteTypeBadge note_type={notesDetails?.note_type} />
          <MidDot />
          <GenderType gender_type={notesDetails?.gender_type} />
          {notesDetails?.location_type !== undefined &&
            notesDetails?.location_type !== null && (
              <>
                <MidDot />
                <LocationType location_type={notesDetails?.location_type} />
              </>
            )}
        </Flex>
        <div
          className={`${
            styles[`${componentName}__main-section-header-action-mobile`]
          }`}
        >
          <CustomiseButton />
          <EditButton />
          <CopyButton />
        </div>
      </Flex>
    </>
  );
};

export default NotesHeader;
