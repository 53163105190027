import { Col, Select, Typography } from "antd";
import { setRecordingDetail } from "../../slices/recordingsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import styles from "./createnotemodal.module.scss";
import { cptCodeOptions } from "../../domain/notes.domain";

const CPTCodeSelector = () => {
  const componentName = "createnotemodal";
  const dispatch = useAppDispatch();

  const { recordingDetail } = useAppSelector((state) => state.recordings);

  return (
    <Col span={12} className={`${styles[`${componentName}__modal-row`]}`}>
      <Typography.Text>CPT Code: (optional)</Typography.Text>

      <Select
        value={recordingDetail.duration_type}
        placeholder="Select CPT Code"
        onChange={(value) => {
          dispatch(
            setRecordingDetail({
              ...recordingDetail,
              duration_type: value,
            })
          );
        }}
        optionFilterProp="label"
        showSearch
        options={cptCodeOptions}
        optionRender={(option) => (
          <div>
            <span style={{ marginRight: 4 }}>{option.data.label}</span>
            {option.data?.desc ? (
              <span style={{ fontSize: 12 }}>{option.data.desc}</span>
            ) : null}
          </div>
        )}
      />
    </Col>
  );
};

export default CPTCodeSelector;
