import { Typography } from "@mui/material";
import { Button, Col, Flex, Form, Input, Row, Divider } from "antd";
import type React from "react";
import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { NavLink, useNavigate } from "react-router-dom";
import HippaLogo from "../../assets/bg/hippa.png";
import LoadingModal from "../../components/LoadingModal";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setIsDemoPath } from "../../slices/appStateSlice";
import { register } from "../../slices/authSlice";
import { fetchUserData } from "../../slices/userSlice";
import { EventType, identifyUser, trackEvent } from "../../utils/analytics";
import styles from "./auth.module.scss";
import axiosInstance from "../../api/axiosInstance";
import Swal from "sweetalert2";
import { useRootPath } from "../../hooks/app.hook";
import { Card } from 'antd';
import MarketingCopy from './MarketingCopy';

type RegisterProps = {
  singleGrid?: boolean;
}

const Register: React.FC<RegisterProps> = ({ singleGrid = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const componentName = "login";
  const dispatch = useAppDispatch();
  const { rootPath } = useRootPath();
  const [isLoading, setIsLoading] = useState(false);

  const { isDemoPath } = useAppSelector((state) => state.appState);

  const handleRegister = async () => {
    setIsLoading(true);

    const { name, email, password } = form.getFieldsValue();

    try {
      const registerationSuccessful = await dispatch(
        register({
          name,
          email,
          password,
        }),
      ).unwrap();

      if (registerationSuccessful) {
        dispatch(setIsDemoPath(false));
        identifyUser(email, { email });
        trackEvent(EventType.REGISTER, { email });
        dispatch(fetchUserData());
        const token = localStorage.getItem("token");
        if (!token) {
          setIsLoading(false);
          return;
        }
        navigate(rootPath);
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleGoogleSignUp = async (credentialResponse: any) => {
    const decoded: any = jwtDecode(credentialResponse.credential);

    try {
      const response = await axiosInstance.post("/accounts/google/login/", {
        access_token: credentialResponse.credential,
        id_token: credentialResponse.credential,
      });

      if (response.data.key) {
        localStorage.setItem("token", response.data.key);
        dispatch(setIsDemoPath(false));
        dispatch(fetchUserData());
        identifyUser(decoded.email, { email: decoded.email });
        trackEvent(EventType.GOOGLE_SIGN_UP, { email: decoded.email });
        navigate(rootPath);
      }
    } catch (error) {
      console.error('Google sign up error:', error);
      Swal.fire({
        title: 'Sign Up Error',
        icon: 'error',
        text: "It seems you might have an existing account. Please try signing in with your email or password instead. If you can't remember your password, you can use the 'Forgot Password' option.",
      });
    }
  };

  const inputElementFocused =
    (event: any) => {
      if (isDemoPath) {
        const { id } = event.target;
        if (id === "name") {
          trackEvent(EventType.CLICKED_ON_FULL_NAME_DEMO_PATH);
        } else if (id === "email") {
          trackEvent(EventType.CLICKED_ON_EMAIL_DEMO_PATH);
        } else if (id === "password") {
          trackEvent(EventType.CLICKED_ON_PASSWORD_DEMO_PATH);
        }
      }
    };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Row className="fullScreen auth-container">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={styles.marketingSection}
        >
          <MarketingCopy />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className={styles.formSection}>
          <Card className={styles.formCard}>
            <Flex vertical justify="center" align="center">
              <Typography variant="h5" className={styles.formTitle}>
                Create Your Free Nudge Account
              </Typography>
              <Typography className={styles.formSubtitle}>
                Get 5 free sessions with your trial.
              </Typography>
              <Typography className={styles.formSubtitle}>
                No credit card required.
              </Typography>
              <div
                style={{
                  marginTop: 10,
                  boxSizing: "border-box",
                }}
              >
                <GoogleLogin
                  onSuccess={handleGoogleSignUp}
                  text="signup_with"
                  onError={() => {
                    console.error("Google Sign Up Failed");
                    Swal.fire({
                      title: "Sign Up Error",
                      icon: "error",
                      text: "It seems you might have an existing account. Please try signing in with your email or password instead. If you can't remember your password, you can use the 'Forgot Password' option.",
                    });
                  }}
                />
              </div>
              <Divider style={{ width: "100%", margin: "10px 0px" }}>OR</Divider>
              <Form
                className={styles.formContainer}
                form={form}
                layout="vertical"
                onFinish={handleRegister}
                autoComplete="off"
              >
                <Form.Item
                  name="name"
                  label="Full Name"
                  rules={[
                    { required: true, message: "Please enter your name." },
                  ]}
                >
                  <Input
                    placeholder="Susan Johnson"
                    onFocus={inputElementFocused}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email.",
                    },
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please ensure a valid email address.",
                    },
                  ]}
                >
                  <Input
                    placeholder="susan@example.com"
                    onFocus={inputElementFocused}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please enter your password." },
                    {
                      min: 5,
                      message: "Password must be at least 5 characters long",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter password"
                    type="password"
                    onFocus={inputElementFocused}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    Create Free Account
                  </Button>
                </Form.Item>
              </Form>
              <div
                style={{
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                By signing up or logging into Nudge, you are agreeing to our
                <NavLink to={"/privacy"}> Privacy Policy</NavLink> and
                <NavLink to={"/terms"}> Terms of Service</NavLink>,
                <a target="_blank" href="https://getnudgeai.com/baa" rel="noopener noreferrer"> Business Associate Agreement</a>.
              </div>
              <Flex
                className={styles[`${componentName}__form-container-text`]}
                justify="center"
                style={{
                  marginTop: "10px",
                }}
              >
                Already a User? &nbsp;<NavLink to={"/login"}>Login</NavLink>
              </Flex>
              <div
                className={
                  styles[`${componentName}__form-container-bottom-notification`]
                }
              >
                Your data is protected with HIPAA-compliant encryption. <br />{" "}
                Nudge removes any personally identifying data, including audio.
              </div>
              <img
                src={HippaLogo}
                alt="Hippa"
                className={styles[`${componentName}__side-image-hippa`]}
              />
            </Flex>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Register;
