import {
  CopyOutlined,
  GoogleOutlined,
  WindowsOutlined,
  YahooOutlined
} from "@ant-design/icons";
import { Button, Drawer, Flex, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import { EventType, ShareModal, trackEvent } from "../../utils/analytics";
import styles from "./share.module.scss";
import { setOpenShare } from "../../slices/appStateSlice";
const Share = () => {
  const componentName = "share";
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileView();
  const { openShare } = useAppSelector((state) => state.appState);
  const handleCopyLink = async () => {
    trackEvent(EventType.SHARE_MODAL_OPEN, {
      shareModalType: ShareModal.COPY_LINK,
    });
    try {
      let data: {
        title?: string;
        url: string;
        text?: string;
      } = {
        title: "Try this tool I’ve been using. It automates notes for mental health clinicians: \n",
        url: "https://getnudgeai.com",
      }
      if (isMobile) {
        data = {
          url: data.url,
          text: "Try this tool I’ve been using. It automates notes for mental health clinicians: \n",
        }
      }
      await navigator.share(data);
    } catch (error) {
      console.log("Error sharing link:", error);
    }
  };

  const openEmailClient = (service: "gmail" | "yahoo" | "outlook") => {
    const subject = "Check out Nudge AI";
    const body =
      "Try this tool I’ve been using. It automates notes for mental health clinicians: \n\n https://getnudgeai.com";
    let mailtoLink = "";

    switch (service) {
      case "gmail":
        mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.GMAIL,
        });
        break;
      case "yahoo":
        mailtoLink = `https://compose.mail.yahoo.com/?to=&subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.YAHOO,
        });
        break;
      case "outlook":
        mailtoLink = `https://outlook.live.com/mail/0/deeplink/compose?to=&subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.OUTLOOK,
        });
        break;
      default:
        break;
    }
    window.open(mailtoLink, "_blank");
  };

  const ShareContainer = () => {
    return <>
      <Flex>
        <Flex className={styles[`${componentName}__share-logo`]}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.23656 10.1183C6.40513 9.78179 6.5 9.40197 6.5 9C6.5 8.59803 6.40513 8.21821 6.23656 7.88172M6.23656 10.1183C5.82611 10.9376 4.97874 11.5 4 11.5C2.61929 11.5 1.5 10.3807 1.5 9C1.5 7.61929 2.61929 6.5 4 6.5C4.97874 6.5 5.82611 7.06243 6.23656 7.88172M6.23656 10.1183L11.7634 12.8817M6.23656 7.88172L11.7634 5.11828M11.7634 5.11828C12.1739 5.93757 13.0213 6.5 14 6.5C15.3807 6.5 16.5 5.38071 16.5 4C16.5 2.61929 15.3807 1.5 14 1.5C12.6193 1.5 11.5 2.61929 11.5 4C11.5 4.40197 11.5949 4.78179 11.7634 5.11828ZM11.7634 12.8817C11.5949 13.2182 11.5 13.598 11.5 14C11.5 15.3807 12.6193 16.5 14 16.5C15.3807 16.5 16.5 15.3807 16.5 14C16.5 12.6193 15.3807 11.5 14 11.5C13.0213 11.5 12.1739 12.0624 11.7634 12.8817Z" stroke="#4F46E5" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Flex>
      </Flex>
      <Flex>
        <div className={styles[`${componentName}__share-title`]}>
          Nudge It Forward
        </div>
      </Flex>
      <Flex className={styles[`${componentName}__share-subtitle`]}>
          Recommend Nudge to help your fellow clinicians save time
      </Flex>
      <Flex className={styles[`${componentName}__share-secondSubtitle`]}>
          You both get a free month when they subscribe
      </Flex>
      <Flex justify="center" >
        <Button className={styles[`${componentName}__share-CTA`]} icon={<CopyOutlined />} type="primary" onClick={handleCopyLink}>
          Share Nudge
        </Button>
      </Flex>
      {!isMobile && <><Flex justify="center" style={{ margin: "10px" }}>
        <div >
          Or send email
        </div>
      </Flex>
        <Flex justify="space-between">
          <div>
            <Button
              icon={<GoogleOutlined />}
              onClick={() => openEmailClient("gmail")}
            >
              Gmail
            </Button>
          </div>
          <div>
            <Button
              icon={<YahooOutlined />}
              onClick={() => openEmailClient("yahoo")}
            >
              Yahoo Mail
            </Button>
          </div>
          <div>
            <Button
              icon={<WindowsOutlined />}
              onClick={() => openEmailClient("outlook")}
            >
              Outlook
            </Button>
          </div>
        </Flex>
      </>}
    </>
  }

  const closeShare = () => {
    dispatch(setOpenShare(false));
  }

  if (isMobile) {
    return <Drawer placement="bottom" open={openShare} onClose={closeShare}>
      <ShareContainer />
    </Drawer>
  }

  return (
    <Modal width={400} open={openShare} onCancel={closeShare} footer={null}>
      <ShareContainer />
    </Modal>
  );
};
export default Share;
