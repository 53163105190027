import "./styles.css"; // Import the CSS for styling

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-circle"></div>
    </div>
  );
};

export default LoadingModal;
