import styles from "./tutorial.module.scss";
const HowToTell = () => {
  const component = "tutorial";
  return (
    <div className={styles[`${component}__state`]}>
      <a
        href="https://getnudgeai.com/tell-patient-about-nudge"
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: "1.15em",
          lineHeight: "1.5em",
          textDecoration: "underline",
        }}
      >
        How do I tell my client/patient about Nudge?
      </a>
    </div>
  );
};

export default HowToTell;
