import { Button } from "antd";
import styles from "./notes.module.scss";
import { useAppSelector } from "../../hooks/redux-hooks";

const SaveButton = ({ onClick }: { onClick: () => void }) => {
  const componentName = "notes";
  const { isDemoPath } = useAppSelector((state) => state.appState);
  return (
    <div className={`${styles[`${componentName}__main-section-action`]}`}>
      {!isDemoPath && <Button
        type="primary"
        onClick={onClick}
        className={`${styles[`${componentName}__main-section-save-button`]}`}
      >
        Save Changes
      </Button>}
    </div>
  );
};

export default SaveButton;
