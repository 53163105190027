import { AudioFilled, PauseOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Flex, Row } from "antd";
import React from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./activerecordingview.module.scss";
import CustomAudioVisualizer from "./CustomAudioVisuazlier";

type ActiveRecordingViewProps = {
  isPaused: boolean;
  recordingTime: number;
  handleStopRecording: () => void;
  handleResumeRecording: () => void;
  handleSubmitAudio: () => void;
  deleteRecording: () => void;
};

const ActiveRecordingView = ({
  isPaused,
  recordingTime,
  handleStopRecording,
  handleResumeRecording,
  handleSubmitAudio,
  deleteRecording,
}: ActiveRecordingViewProps) => {
  const componentName = "ActiveRecordingView";

  const { isSubmissionInProgress } = useAppSelector((state) => state.appState);
  const { recordingDetail, mediaRecorder } = useAppSelector(
    (state) => state.recordings
  );
  const { clients } = useAppSelector((state) => state.client);

  const client = React.useMemo(() => {
    return clients.find((client) => client.id === recordingDetail?.client_id);
  }, [recordingDetail, clients]);
  return (
    <Card className={styles[`${componentName}__container-right-container`]}>
      <Row>
        {recordingDetail?.client_id && client && (
          <Col className={styles[`${componentName}__container-right-user`]}>
            <span>
              <Avatar
                style={{
                  backgroundColor: getRandomAvatarColor(client.name),
                }}
                size={"small"}
              >
                {client.name[0].toUpperCase()}
              </Avatar>{" "}
              &nbsp;
              {client.name}
            </span>
          </Col>
        )}
        <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
          <Card
            className={`${
              styles[`${componentName}__container-right-recording-card`]
            }`}
          >
            {!isPaused && (
              <div className={styles[`${componentName}__modal-logo`]}>
                <AudioFilled style={{ fontSize: "20px", color: "#EF4444" }} />
              </div>
            )}
            {isPaused && (
              <Flex justify="center">
                <PauseOutlined
                  style={{
                    fontSize: "25px",
                    color: "var(--grey-text)",
                    margin: "10px 0px",
                  }}
                />
              </Flex>
            )}
            <Flex
              className={styles[`${componentName}__card-right-text`]}
              justify="center"
              vertical
              gap={10}
            >
              <Flex
                justify="center"
                align="center"
                gap={10}
                className={styles[`${componentName}__container-text`]}
              >
                {isPaused ? "Captured" : "Capturing"} session
              </Flex>

              <span className={styles[`${componentName}__modal-sub-text`]}>
                {Math.floor(recordingTime / 60)}:
                {String(recordingTime % 60).padStart(2, "0")} (max: 120 mins)
              </span>

              {mediaRecorder ? (
                <div>
                  <CustomAudioVisualizer
                    mediaRecorder={mediaRecorder}
                    width={200}
                    height={25}
                    barColor="rgb(17, 185, 129)"
                  />
                </div>
              ) : null}
            </Flex>
          </Card>
        </Col>
        <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
          {!isPaused && (
            <Row gutter={[10, 10]}>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="default"
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  onClick={handleStopRecording}
                >
                  Pause
                </Button>
              </Col>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="primary"
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  onClick={handleStopRecording}
                >
                  End Session
                </Button>
              </Col>
            </Row>
          )}
          {isPaused && (
            <Row gutter={[10, 10]}>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  type="default"
                  disabled={isSubmissionInProgress}
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  } `}
                  onClick={handleResumeRecording}
                >
                  Resume
                </Button>
              </Col>
              <Col span={12} md={24} lg={24} xl={12}>
                <Button
                  loading={isSubmissionInProgress}
                  disabled={isSubmissionInProgress}
                  type="primary"
                  className={`${styles[`${componentName}__modal-row-button`]} ${
                    styles[`${componentName}__modal-row-button-big`]
                  }`}
                  onClick={handleSubmitAudio}
                >
                  Submit Audio
                </Button>
              </Col>
            </Row>
          )}
          {isPaused && (
            <Flex
              gap={10}
              justify="center"
              className={`${styles[`${componentName}__modal-row-secondary`]}`}
            >
              <span onClick={deleteRecording}>Cancel recording</span>
            </Flex>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ActiveRecordingView;
