import { Badge } from "antd";
import { getNotesAndBadge } from "../../domain/notes.domain";

type Props = {
  note_type?: number | null | undefined;
};

export default function NoteTypeBadge({ note_type }: Props) {
  if (note_type !== null && note_type !== undefined) {
    const { name, badgeStyle } = getNotesAndBadge(note_type);
    return <Badge count={name} showZero className={badgeStyle} />;
  }
  return null;
}
