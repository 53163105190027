import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TNotes } from "../types/index.type";

// Define a type for the slice state
type NotesState = {
  recordingId: number | undefined;
  notesDetails: TNotes | null;
  emailContent: string;
  activeKey: string;
}

// Initial state
const initialState: NotesState = {
  recordingId: undefined,
  notesDetails: null,
  emailContent: "",
  activeKey: "1"
};

// Slice
const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setRecordingId: (state, action: PayloadAction<number>) => {
      state.recordingId = action.payload;
    },
    setNotesDetails: (state, action: PayloadAction<TNotes | null>) => {
      state.notesDetails = action.payload;
    },
    setEmailContent: (state, action: PayloadAction<string>) => {
      state.emailContent = action.payload;
    },
    setActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    resetNotesDetails: (state) => {
      state.recordingId = undefined;
      state.notesDetails = null;
      state.emailContent = "";
      state.activeKey = "1";
    },
  },
});

// Actions
export const {
  setRecordingId,
  setNotesDetails,
  resetNotesDetails,
  setEmailContent,
  setActiveKey,
} = notesSlice.actions;

// Reducer
export default notesSlice.reducer;
