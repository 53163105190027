import { Typography } from "@mui/material";
import { Button, Col, Flex, Form, Input, Row, message } from "antd";
import queryString from "query-string";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import HippaLogo from "../../assets/bg/hippa.png";
import { EventType, trackEvent } from "../../utils/analytics";
import RightSideAuth from "./auth-right-side.component";
import styles from "./auth.module.scss";

export default function ResetPassword() {
  const componentName = "login";
  const navigate = useNavigate();
  const location = useLocation();
  const { uid, token } = queryString.parse(location.search);
  const [form] = Form.useForm();

  const handleForgetPassword =
    async ({
      password,
      confirmPassword,
    }: {
      password: string;
      confirmPassword: string;
    }) => {
      if (password !== confirmPassword) {
        message.error("Passwords do not match");
        return;
      }
        const urlSuffix = "/users/password-reset/confirm/";
        axiosInstance.post(urlSuffix, {
          uid,
          token,
          new_password1: password,
          new_password2: confirmPassword,
        }).then(() => {
          message.success("Password has been reset successfully!");
          navigate("/login");
          trackEvent(EventType.RESET_PASSWORD);
        }).catch((err) => {
          console.error(err);
          message.error("An error occurred while resetting your password.");
        });
    };
  return (
    <Row className="fullScreen">
      <Col
        span={24}
        md={24}
        lg={12}
        className={styles[`${componentName}__left`]}
      >
        <Flex
          className={styles[`${componentName}__form`]}
          vertical
          justify="center"
        >
          <Typography
            fontWeight={500}
            fontSize={30}
            variant="h5"
            textAlign={"center"}
            margin={4}
          >
            Reset Password
          </Typography>
          <Form
            className={styles[`${componentName}__form-container`]}
            form={form}
            layout="vertical"
            onFinish={handleForgetPassword}
            autoComplete="off"
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required: true, message: "Please input your password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginTop: "10px" }}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
          <Flex
            className={styles[`${componentName}__form-container-text`]}
            justify="center"
          >
            Already User ? &nbsp;<NavLink to={"/login"}>Login</NavLink>
          </Flex>
          <div
            className={
              styles[`${componentName}__form-container-bottom-notification`]
            }
          >
            Nudge is HIPAA compliant and redacts PHI. <br />
            Nudge removes any personally identifying data, including audio.
          </div>
          <img
            alt="HIPPA"
            src={HippaLogo}
            className={styles[`${componentName}__side-image-hippa`]}
          />
        </Flex>
      </Col>
      <RightSideAuth />
    </Row>
  );
}
