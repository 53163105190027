import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../service/auth.service";
import { identifyUser } from "../utils/analytics";

type UserState = {
  data: any;
  loading: boolean;
  error: string | null;
  showPaymentModal: boolean;
}

const initialState: UserState = {
  data: null,
  loading: false,
  error: null,
  showPaymentModal: false,
};

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async () => {
    const response = await getUserData();
    if (response.status === 200) {
      const email = response.data.email;
      identifyUser(email, { email });
    }
    return response.data;
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setShowPaymentModal: (state, action) => {
      state.showPaymentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch user data";
      });
  },
});

export const { setShowPaymentModal } = userSlice.actions;

export default userSlice.reducer;
