import { useCallback, useEffect } from "react";
import { useAppSelector } from "./redux-hooks";
import { useIntercom } from "react-use-intercom";
import { useIsMobileView } from "./ui-hook";

const useIntercomWithData = () => {
  const isMobile = useIsMobileView();
  const user = useAppSelector((state) => state.user);
  const { userInfo } = useAppSelector((state) => state.auth)
  const { boot, shutdown, show } = useIntercom();

  const bootWithData = useCallback(() => {
    if (window?.Intercom && typeof window?.Intercom === "function")
      boot({
        userId: user.data?.id,
        email: user.data?.email,
        name: user.data?.name,
        userHash: user.data?.intercom_user_hash,
      });
  }, [
    boot,
    user.data?.id,
    user.data?.email,
    user.data?.name,
    user.data?.intercom_user_hash,
  ]);

  useEffect(() => {
    if (userInfo.total_recordings === 0) {
      bootWithData();
      setTimeout(() => {
        if(!isMobile) {
          show();
        }
      }, 3000);
    }
  }, [userInfo.total_recordings, bootWithData, show, isMobile]);

  return { bootWithData, shutdown };
};

export default useIntercomWithData;
