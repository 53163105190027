import { LOCATION_TYPE } from "../../domain/notes.domain";

type Props = {
  location_type?: number | null | undefined;
  isStyled?: boolean;
};

export default function LocationType({
  location_type,
  isStyled = true,
}: Props) {
  const style = { color: "#1E3A8A", fontSize: "14px", fontWeight: "500" };
  if (location_type === LOCATION_TYPE.TELEHEALTH)
    return <span style={isStyled ? style : {}}>Telehealth</span>;
  if (location_type === LOCATION_TYPE.IN_PERSON)
    return <span style={isStyled ? style : {}}>In-person</span>;
  else return <></>;
}
