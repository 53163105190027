import { useEffect, useRef, useCallback } from "react";

type CustomAudioVisualizerProps = {
  mediaRecorder: MediaRecorder;
  width: number;
  height: number;
  barColor: string;
};

const CustomAudioVisualizer: React.FC<CustomAudioVisualizerProps> = ({
  mediaRecorder,
  width,
  height,
  barColor,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);

  const draw = useCallback(() => {
    if (!canvasRef.current || !analyserRef.current || !dataArrayRef.current)
      return;

    const canvasCtx = canvasRef.current.getContext("2d");
    if (!canvasCtx) return;

    if (
      mediaRecorder.state === "paused" ||
      mediaRecorder.state === "inactive"
    ) {
      canvasCtx.clearRect(0, 0, width, height);
      return;
    }

    analyserRef.current.getByteFrequencyData(dataArrayRef.current);

    canvasCtx.clearRect(0, 0, width, height);

    const barWidth = (width / dataArrayRef.current.length) * 2.5;
    let barHeight: number;
    let x = 0;

    for (let i = 0; i < dataArrayRef.current.length; i++) {
      barHeight = dataArrayRef.current[i];

      canvasCtx.fillStyle = barColor;
      canvasCtx.fillRect(x, height - barHeight / 2, barWidth, barHeight / 2);

      x += barWidth + 1;
    }

    animationFrameIdRef.current = requestAnimationFrame(draw);
  }, [width, height, barColor, mediaRecorder.state]);

  useEffect(() => {
    if (mediaRecorder && canvasRef.current) {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      audioContextRef.current = audioContext;
      analyserRef.current = analyser;
      dataArrayRef.current = dataArray;

      const source = audioContext.createMediaStreamSource(mediaRecorder.stream);
      source.connect(analyser);

      draw();

      return () => {
        if (animationFrameIdRef.current) {
          cancelAnimationFrame(animationFrameIdRef.current);
        }
        audioContext.close();
      };
    }
  }, [mediaRecorder, draw]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default CustomAudioVisualizer;
