import axiosInstance from "../api/axiosInstance";

type TPasswordResetBody = {
  new_password1: string;
  new_password2: string;
  old_password: string;
};

export const handleChangePassword = (passwordResetBody: TPasswordResetBody) => {
  return axiosInstance.post(`/rest-auth/password/change/`, passwordResetBody);
};

export const getUserData = () => {
  return axiosInstance.get(`/users/user`);
};

export const getUserInfo = () => {
  return axiosInstance.get(`/users/userinfo/`);
};
